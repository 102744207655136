import { useState } from "react";
import { Pane } from "react-leaflet";
import { getAllGeometryAssets } from "../app/networkSlice";
import Asset from "./Asset";
import { useSelector } from "react-redux";
import {
  findAll,
  updateConnectionPoint,
  updateGroupedConnection,
  updateMotor,
  updateNode,
  updatePointOfConnection,
  updateTransformer,
  updateWelder,
} from "../app/networkSlice";
import ResultMarker from "./ResultMarker";

const Network = ({
  networkId,
  handleSelect,
  clickedAsset,
  handleMove,
  reshapeCablePoints,
  renderer,
  fallbackRenderer,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const groupedConnections = useSelector((state) => state.network.present.groupedConnections);
  const allGeometryAssets = useSelector((state) => getAllGeometryAssets(state));

  const isNode = (groupedConnection) => {
    return !groupedConnection.groupedConnectionPoints.length && !groupedConnection.linkBox;
  };

  return (
    <>
      <Pane name="network-transformers" style={{ zIndex: "590" }}>
        {useSelector((state) => state.network.present.transformers).map((transformer) => (
          <Asset
            key={transformer.id}
            networkId={networkId}
            asset={transformer}
            onClick={handleSelect}
            onMove={(e, a) => handleMove(e, a, updateTransformer)}
            highlighted={clickedAsset && transformer.id === clickedAsset.id}
            reshapeCablePoints={reshapeCablePoints}
            isDragging={isDragging}
            setIsDragging={(value) => setIsDragging(value)}
            allGeometryAssets={allGeometryAssets}
          ></Asset>
        ))}
      </Pane>
      <Pane name="network-connection-points" style={{ zIndex: "570" }}>
        {useSelector((state) => state.network.present.connectionPoints).map((connectionPoint) => (
          <Asset
            key={connectionPoint.id}
            networkId={networkId}
            asset={connectionPoint}
            onClick={handleSelect}
            onMove={(e, a) => handleMove(e, a, updateConnectionPoint)}
            highlighted={clickedAsset && connectionPoint.id === clickedAsset.id}
            reshapeCablePoints={reshapeCablePoints}
            isDragging={isDragging}
            setIsDragging={(value) => setIsDragging(value)}
            allGeometryAssets={allGeometryAssets}
          ></Asset>
        ))}
        {useSelector((state) => state.network.present.motors).map((motor) => (
          <Asset
            key={motor.id}
            networkId={networkId}
            asset={motor}
            onClick={handleSelect}
            onMove={(e, a) => handleMove(e, a, updateMotor)}
            highlighted={clickedAsset && motor.id === clickedAsset.id}
            reshapeCablePoints={reshapeCablePoints}
            isDragging={isDragging}
            setIsDragging={(value) => setIsDragging(value)}
            allGeometryAssets={allGeometryAssets}
          ></Asset>
        ))}
        {useSelector((state) => state.network.present.welders).map((welder) => (
          <Asset
            key={welder.id}
            networkId={networkId}
            asset={welder}
            onClick={handleSelect}
            onMove={(e, a) => handleMove(e, a, updateWelder)}
            highlighted={clickedAsset && welder.id === clickedAsset.id}
            reshapeCablePoints={reshapeCablePoints}
            isDragging={isDragging}
            setIsDragging={(value) => setIsDragging(value)}
            allGeometryAssets={allGeometryAssets}
          ></Asset>
        ))}
        {useSelector((state) => state.network.present.pointOfConnections).map(
          (pointOfConnection) => (
            <Asset
              key={pointOfConnection.id}
              networkId={networkId}
              asset={pointOfConnection}
              onClick={handleSelect}
              onMove={(e, a) => handleMove(e, a, updatePointOfConnection)}
              highlighted={clickedAsset && pointOfConnection.id === clickedAsset.id}
              reshapeCablePoints={reshapeCablePoints}
              isDragging={isDragging}
              setIsDragging={(value) => setIsDragging(value)}
              allGeometryAssets={allGeometryAssets}
            ></Asset>
          ),
        )}
        {groupedConnections
          .filter((gc) => !isNode(gc))
          .map((groupedConnection) => (
            <Asset
              key={groupedConnection.id}
              label={groupedConnection.count}
              networkId={networkId}
              asset={groupedConnection}
              onClick={handleSelect}
              onMove={(e, a) => handleMove(e, a, updateGroupedConnection)}
              highlighted={clickedAsset && groupedConnection.id === clickedAsset.id}
              reshapeCablePoints={reshapeCablePoints}
              isDragging={isDragging}
              setIsDragging={(value) => setIsDragging(value)}
              allGeometryAssets={allGeometryAssets}
            ></Asset>
          ))}
      </Pane>
      <Pane name="network-nodes" style={{ zIndex: 560 }}>
        {groupedConnections
          .filter((gc) => isNode(gc))
          .map((groupedConnection) => (
            <Asset
              key={groupedConnection.id}
              networkId={networkId}
              asset={groupedConnection}
              onClick={handleSelect}
              onMove={(e, a) => handleMove(e, a, updateGroupedConnection)}
              highlighted={clickedAsset && groupedConnection.id === clickedAsset.id}
              reshapeCablePoints={reshapeCablePoints}
              isDragging={isDragging}
              setIsDragging={(value) => setIsDragging(value)}
              allGeometryAssets={allGeometryAssets}
            ></Asset>
          ))}
        {useSelector((state) => state.network.present.nodes).map((node) => (
          <Asset
            key={node.id}
            networkId={networkId}
            asset={node}
            onClick={handleSelect}
            onMove={(e, a) => handleMove(e, a, updateNode)}
            highlighted={clickedAsset && node.id === clickedAsset.id}
            reshapeCablePoints={reshapeCablePoints}
            isDragging={isDragging}
            setIsDragging={(value) => setIsDragging(value)}
            allGeometryAssets={allGeometryAssets}
          ></Asset>
        ))}
      </Pane>
      <div name="network-cables" style={{ zIndex: 530 }}>
        {useSelector((state) => state.network.present.cables).map((cable) => (
          <Asset
            key={cable.id}
            networkId={networkId}
            asset={cable}
            onClick={handleSelect}
            highlighted={clickedAsset && cable.id === clickedAsset.id}
            reshapeCablePoints={reshapeCablePoints}
            isDragging={isDragging}
            setIsDragging={(value) => setIsDragging(value)}
            renderer={renderer}
            fallbackRenderer={fallbackRenderer}
            allGeometryAssets={allGeometryAssets}
          ></Asset>
        ))}
      </div>
      <Pane name="network-results" style={{ zIndex: 550 }}>
        {useSelector((state) => findAll(state)).map((asset) => (
          <>
            <ResultMarker
              key={asset.asset.id}
              asset={asset.asset}
              onClick={handleSelect}
              isDragging={isDragging}
            />
          </>
        ))}
      </Pane>
    </>
  );
};

export default Network;
