import { useMsal } from "@azure/msal-react";
import { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Searchbox.css";

import { Button, Input, InputGroup, InputGroupAddon } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { ToolContext } from "../context/ToolContext";
import { getLatLngFromWktPoint } from "../model/viewModel/geometryWktConverter";
import { loadFeederList } from "../services/apiService";
import WarningModal from "./WarningModal";
import { FormContext } from "../context/FormContext";
import { isRagNetworkPopulated } from "../utils/networkFunctions";
import { getSubstationId, getTransformerSearchValue } from "../utils/transformerFunctions";
import { setBrowserTabTitle } from "../utils/browserFunctions";
import { setAsSaved, setTitle } from "../app/networkSlice";
import { store } from "../app/store";
import { clearHistoryAction } from "../app/undoable";
import * as alert from "../constants/alert";
import useBearerToken from "../utils/useBearerToken";

const Searchbox = ({ loadedTransformers }) => {
  const { toolState, setToolState } = useContext(ToolContext);
  const { searchValue, useAntData, showFeederList } = toolState;
  const { formState, dispatch } = useContext(FormContext);
  const [showRAGWarning, setShowRAGWarning] = useState(false);
  const { ragNetworks, clientSettings } = formState;
  const networkChanged = useSelector((state) => state.network.present.networkChanged);

  const { TransformerNameSearchEnabled } = clientSettings.features;

  const dispatchRedux = useDispatch();

  const { instance, accounts } = useMsal();
  const getToken = useBearerToken();

  useEffect(() => {
    if (showFeederList) {
      getFeeders(true);
      setToolState({ showFeederList: false });
    }
  }, [showFeederList]);

  const getFeeders = async (canProcess) => {
    setShowRAGWarning(false);

    store.dispatch(clearHistoryAction());
    dispatchRedux(setAsSaved());

    if (canProcess !== true) {
      return;
    }

    isRagNetworkPopulated(formState.ragNetworks) && replaceRagNetworksState();

    clientSettings.features.StudyBrowserTitleEnabled &&
      setBrowserTabTitle(clientSettings.general.displayName);
    dispatchRedux(setTitle(null));

    const parsedSearchValue = getSubstationId(searchValue);
    if (!parsedSearchValue) return;

    setToolState({
      isLoading: "Loading network",
    });

    const token = await getToken(instance, accounts);
    const response = await loadFeederList(token, parsedSearchValue, useAntData);

    const allWarnings = response?.data?.network?.messages?.every((s) => s.level === "Warning");
    const anyMessages = response?.data?.network?.messages?.length > 0;

    if (allWarnings && anyMessages) {
      setToolState({
        alert: {
          type: alert.WARNING,
          className: alert.WARNING,
          messages: response.data.network.messages,
        },
        isLoading: false,
      });
    }
    if (!allWarnings && anyMessages) {
      setToolState({
        alert: {
          type: alert.ERROR,
          className: alert.DANGER,
          messages: response.data.network.messages,
        },
        isLoading: false,
      });
      return;
    }

    const transformer = getTransformer(response.data.network);

    setToolState({
      isLoading: false,
      drawBoundary: false,
      ringfenced: [],
      ringfencedFiltered: [],
      ringfencedTypes: [],
      disableBoundary: false,
      feederList: response.data.network.feeders,
      navigateToLatLng: getTransformerLatLng(transformer),
      selectedAssetId: parseInt(getTransformerId(transformer)),
      errors: {},
    });
  };

  const replaceRagNetworksState = () => {
    const _formState = { ...formState };
    _formState.ragNetworks = [];
    dispatch({
      form: "ragNetworks",
      obj: _formState.ragNetworks,
      type: "REPLACE_STATE4",
    });
    if (networkChanged === false) {
      dispatch({
        obj: _formState.ragNetworks,
        type: "SAVE_NETWORK",
      });
    }
  };

  const getTransformer = (network) => {
    return network.conductingEquipment.find((ce) => ce.type === "PowerTransformer");
  };

  const getTransformerLatLng = (transformer) => {
    const geometry = transformer.properties.find((p) => p.name === "geometry");

    return getLatLngFromWktPoint(geometry.value);
  };

  const getTransformerId = (transformer) => {
    return transformer.properties.find((p) => p.name === "wpdId").value;
  };

  return (
    <>
      {showRAGWarning && (
        <WarningModal
          item={true}
          action={getFeeders}
          msg={"Clicking OK will clear the RAG network - are you sure?"}
          yesLabel="OK"
          dismissLabel="Cancel"
        />
      )}
      <div className="p-3">
        <InputGroup>
          {TransformerNameSearchEnabled ? (
            <>
              {loadedTransformers && loadedTransformers.length > 0 ? (
                <Typeahead
                  id="substation-typeahead-search"
                  size="sm"
                  align="left"
                  labelKey={(option) => `${option.key}${option.key ? " " : ""}${option.value}`}
                  options={loadedTransformers}
                  placeholder="Choose a transformer"
                  selected={searchValue || []}
                  onChange={(value) =>
                    setToolState({
                      searchValue: value,
                      feederList: null,
                    })
                  }
                />
              ) : (
                <div className="form-control form-control-sm">
                  <FontAwesomeIcon icon={faSpinner} spin />
                </div>
              )}
            </>
          ) : (
            <Input
              type="text"
              maxLength={50}
              id="substation-search"
              placeholder="Search for a Network"
              bsSize="sm"
              value={getSubstationId(searchValue)}
              onChange={(e) => {
                setToolState({
                  searchValue: getTransformerSearchValue(e.target.value, ""),
                  feederList: null,
                });
              }}
            />
          )}
          <InputGroupAddon addonType="append">
            <Button
              id="btnSearchSub"
              size="sm"
              color="primary"
              disabled={!getSubstationId(searchValue)}
              onClick={() =>
                isRagNetworkPopulated(ragNetworks) ? setShowRAGWarning(true) : getFeeders(true)
              }
            >
              Search
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </div>
    </>
  );
};

export default Searchbox;
