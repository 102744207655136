import { LatLng } from "./simpleTypes";
import { Asset } from "./asset";
import {
  ConnectionPointProperties,
  ConsumptionType,
  IConnectionPointProperties,
} from "./connectionPointProperties";

export class ConnectionPoint extends Asset implements IConnectionPointProperties {
  constructor(id: string, connectionPointProperties: ConnectionPointProperties) {
    super(id);
    this.annotation = connectionPointProperties.annotation;
    this.consumerType = connectionPointProperties.consumerType;
    this.consumerTypeIsDefault = connectionPointProperties.consumerTypeIsDefault;
    this.consumptionType = connectionPointProperties.consumptionType;
    this.consumptionTypeIsDefault = connectionPointProperties.consumptionTypeIsDefault;
    this.autoPhase = connectionPointProperties.autoPhase;
    this.phaseAutoConsumers = connectionPointProperties.phaseAutoConsumers;
    this.phase1Consumers = connectionPointProperties.phase1Consumers;
    this.phase2Consumers = connectionPointProperties.phase2Consumers;
    this.phase3Consumers = connectionPointProperties.phase3Consumers;
    this.balancedLoad = connectionPointProperties.balancedLoad;
    this.unbalancePercent = connectionPointProperties.unbalancePercent;
    this.lowLoadedPhase = connectionPointProperties.lowLoadedPhase;
    this.consumptionValue1 = connectionPointProperties.consumptionValue1;
    this.consumptionValue1IsDefault = connectionPointProperties.consumptionValue1IsDefault;
    this.consumptionValue2 = connectionPointProperties.consumptionValue2;
    this.consumptionValue2IsDefault = connectionPointProperties.consumptionValue2IsDefault;
    this.geometry = connectionPointProperties.geometry;
    this.maxVoltDropPercent = connectionPointProperties.maxVoltDropPercent;
    this.maxVoltDropPercentPassResult = connectionPointProperties.maxVoltDropPercentPassResult;
    this.maxVoltRiseTotal = connectionPointProperties.maxVoltRiseTotal;
    this.maxVoltRiseGeneration = connectionPointProperties.maxVoltRiseGeneration;
    this.maxVoltRiseUnbalance = connectionPointProperties.maxVoltRiseUnbalance;
    this.numberOfPhases = connectionPointProperties.numberOfPhases;
    this.numberOfPhasesIsDefault = connectionPointProperties.numberOfPhasesIsDefault;
    this.styles = connectionPointProperties.styles;
    this.parentId = connectionPointProperties.parentId;
    this.isSubGroupConnection = connectionPointProperties.isSubGroupConnection;
    this.isGenerator = connectionPointProperties.isGenerator;
    this.earthLoopImpedance = connectionPointProperties.earthLoopImpedance;

    this.threePhaseFaultCurrent = connectionPointProperties.threePhaseFaultCurrent;

    this.phaseToPhaseFaultCurrent = connectionPointProperties.phaseToPhaseFaultCurrent;

    this.singlePhaseToEarthFaultCurrent = connectionPointProperties.singlePhaseToEarthFaultCurrent;

    this.phaseMap = connectionPointProperties.phaseMap ? connectionPointProperties.phaseMap : [];
    this.hasFuseWarning = connectionPointProperties.hasFuseWarning;
    this.flickerPassResult = connectionPointProperties.flickerPassResult;
    this.flickerDeltaVoltage = connectionPointProperties.flickerDeltaVoltage;
    this.flickerP28Limit = connectionPointProperties.flickerP28Limit;
    this.flickerStartingPassResult = connectionPointProperties.flickerStartingPassResult;
    this.flickerStartingDeltaVoltage = connectionPointProperties.flickerStartingDeltaVoltage;
    this.impedanceLoopOperating = connectionPointProperties.impedanceLoopOperating;
    this.impedanceLoopOperatingPassResult =
      connectionPointProperties.impedanceLoopOperatingPassResult;
    this.impedanceSourceLoopOperating = connectionPointProperties.impedanceSourceLoopOperating;
    this.impedanceSourceLoopOperatingPassResult =
      connectionPointProperties.impedanceSourceLoopOperatingPassResult;
    this.impedanceLoopFault = connectionPointProperties.impedanceLoopFault;
    this.impedancePhaseOperating = connectionPointProperties.impedancePhaseOperating;
    this.impedanceSourcePhaseOperating = connectionPointProperties.impedanceSourcePhaseOperating;
    this.impedanceSourcePhasePhaseOperating =
      connectionPointProperties.impedanceSourcePhasePhaseOperating;
    this.impedancePhaseFault = connectionPointProperties.impedancePhaseFault;
    this.resistanceLoopOperating = connectionPointProperties.resistanceLoopOperating;
    this.resistanceSourceLoopOperating = connectionPointProperties.resistanceSourceLoopOperating;
    this.resistanceLoopFault = connectionPointProperties.resistanceLoopFault;
    this.resistancePhaseOperating = connectionPointProperties.resistancePhaseOperating;
    this.resistanceSourcePhaseOperating = connectionPointProperties.resistanceSourcePhaseOperating;
    this.resistanceSourcePhasePhaseOperating =
      connectionPointProperties.resistanceSourcePhasePhaseOperating;
    this.resistancePhaseFault = connectionPointProperties.resistancePhaseFault;
    this.reactanceLoopOperating = connectionPointProperties.reactanceLoopOperating;
    this.reactanceSourceLoopOperating = connectionPointProperties.reactanceSourceLoopOperating;
    this.reactanceLoopFault = connectionPointProperties.reactanceLoopFault;
    this.reactancePhaseOperating = connectionPointProperties.reactancePhaseOperating;
    this.reactanceSourcePhaseOperating = connectionPointProperties.reactanceSourcePhaseOperating;
    this.reactanceSourcePhasePhaseOperating =
      connectionPointProperties.reactanceSourcePhasePhaseOperating;
    this.reactancePhaseFault = connectionPointProperties.reactancePhaseFault;
    this.AllmaxVoltDropPercent = connectionPointProperties.AllmaxVoltDropPercent;
    this.AllmaxVoltDropPercentPeriod = connectionPointProperties.AllmaxVoltDropPercentPeriod;
    this.AllmaxVoltDropPercentPassResult =
      connectionPointProperties.AllmaxVoltDropPercentPassResult;
    this.AllmaxVoltDropPhase = connectionPointProperties.AllmaxVoltDropPhase;
    this.AllmaxVoltRiseTotal = connectionPointProperties.AllmaxVoltRiseTotal;
    this.AllmaxVoltRiseGeneration = connectionPointProperties.AllmaxVoltRiseGeneration;
    this.AllmaxVoltRiseUnbalance = connectionPointProperties.AllmaxVoltRiseUnbalance;
    this.AllmaxVoltRisePercentPeriod = connectionPointProperties.AllmaxVoltRisePercentPeriod;
    this.AllmaxVoltRisePhase = connectionPointProperties.AllmaxVoltRisePhase;
    this.AllmaxVoltage = connectionPointProperties.AllmaxVoltage;
    this.AllmaxVoltagePhase1 = connectionPointProperties.AllmaxVoltagePhase1;
    this.AllmaxVoltagePhase2 = connectionPointProperties.AllmaxVoltagePhase2;
    this.AllmaxVoltagePhase3 = connectionPointProperties.AllmaxVoltagePhase3;
    this.AllminVoltage = connectionPointProperties.AllminVoltage;
    this.AllminVoltagePhase1 = connectionPointProperties.AllminVoltagePhase1;
    this.AllminVoltagePhase2 = connectionPointProperties.AllminVoltagePhase2;
    this.AllminVoltagePhase3 = connectionPointProperties.AllminVoltagePhase3;
    this.wintermaxVoltDropPercent = connectionPointProperties.wintermaxVoltDropPercent;
    this.wintermaxVoltDropPercentPeriod = connectionPointProperties.wintermaxVoltDropPercentPeriod;
    this.wintermaxVoltDropPercentPassResult =
      connectionPointProperties.wintermaxVoltDropPercentPassResult;
    this.wintermaxVoltDropPhase = connectionPointProperties.wintermaxVoltDropPhase;
    this.wintermaxVoltRisePercent = connectionPointProperties.wintermaxVoltRisePercent;
    this.wintermaxVoltRisePercentPeriod = connectionPointProperties.wintermaxVoltRisePercentPeriod;
    this.wintermaxVoltRiseTotal = connectionPointProperties.wintermaxVoltRiseTotal;
    this.wintermaxVoltRiseGeneration = connectionPointProperties.wintermaxVoltRiseGeneration;
    this.wintermaxVoltRiseUnbalance = connectionPointProperties.wintermaxVoltRiseUnbalance;
    this.wintermaxVoltRisePhase = connectionPointProperties.wintermaxVoltRisePhase;
    this.wintermaxVoltage = connectionPointProperties.wintermaxVoltage;
    this.wintermaxVoltagePhase1 = connectionPointProperties.wintermaxVoltagePhase1;
    this.wintermaxVoltagePhase2 = connectionPointProperties.wintermaxVoltagePhase2;
    this.wintermaxVoltagePhase3 = connectionPointProperties.wintermaxVoltagePhase3;
    this.winterminVoltage = connectionPointProperties.winterminVoltage;
    this.winterminVoltagePhase1 = connectionPointProperties.winterminVoltagePhase1;
    this.winterminVoltagePhase2 = connectionPointProperties.winterminVoltagePhase2;
    this.winterminVoltagePhase3 = connectionPointProperties.winterminVoltagePhase3;
    this.summermaxVoltDropPercent = connectionPointProperties.summermaxVoltDropPercent;
    this.summermaxVoltDropPercentPeriod = connectionPointProperties.summermaxVoltDropPercentPeriod;
    this.summermaxVoltDropPercentPassResult =
      connectionPointProperties.summermaxVoltDropPercentPassResult;
    this.summermaxVoltDropPhase = connectionPointProperties.summermaxVoltDropPhase;
    this.summermaxVoltRisePercent = connectionPointProperties.summermaxVoltRisePercent;
    this.summermaxVoltRiseTotal = connectionPointProperties.summermaxVoltRiseTotal;
    this.summermaxVoltRiseGeneration = connectionPointProperties.summermaxVoltRiseGeneration;
    this.summermaxVoltRiseUnbalance = connectionPointProperties.summermaxVoltRiseUnbalance;
    this.summermaxVoltRisePercentPeriod = connectionPointProperties.summermaxVoltRisePercentPeriod;
    this.summermaxVoltRisePhase = connectionPointProperties.summermaxVoltRisePhase;
    this.summermaxVoltage = connectionPointProperties.summermaxVoltage;
    this.summermaxVoltagePhase1 = connectionPointProperties.summermaxVoltagePhase1;
    this.summermaxVoltagePhase2 = connectionPointProperties.summermaxVoltagePhase2;
    this.summermaxVoltagePhase3 = connectionPointProperties.summermaxVoltagePhase3;
    this.summerminVoltage = connectionPointProperties.summerminVoltage;
    this.summerminVoltagePhase1 = connectionPointProperties.summerminVoltagePhase1;
    this.summerminVoltagePhase2 = connectionPointProperties.summerminVoltagePhase2;
    this.summerminVoltagePhase3 = connectionPointProperties.summerminVoltagePhase3;
    this.phaseImpedance = connectionPointProperties.phaseImpedance;
    this.loopImpedance = connectionPointProperties.loopImpedance;
    this.nodeNumber = connectionPointProperties.nodeNumber;
    this.groupId = connectionPointProperties.groupId;
    this.status = connectionPointProperties.status;
    this.cutOutSize = connectionPointProperties.cutOutSize;
    this.multiOccupancyContainer = connectionPointProperties.multiOccupancyContainer;
    this.multiOccupancy = connectionPointProperties.multiOccupancy;
    this.moSpareWays = connectionPointProperties.moSpareWays;
    this.potEnds = connectionPointProperties.potEnds;
    this.linkBox = connectionPointProperties.linkBox;
    this.overrideGroundType = connectionPointProperties.overrideGroundType;
    this.pole = connectionPointProperties.pole;
    this.count = connectionPointProperties.count;
    this.rootTransformerId = connectionPointProperties.rootTransformerId;
    this.substationId = connectionPointProperties.substationId;
    this.feederNumber = connectionPointProperties.feederNumber;
    this.classIsDefault = connectionPointProperties.classIsDefault;
    this.MPAN = connectionPointProperties.MPAN;
    this.premisesId = connectionPointProperties.premisesId;
  }

  [key: string]: any;
  annotation: string;
  consumerType: string;
  consumerTypeIsDefault: Boolean;
  autoPhase: Boolean;
  phaseAutoConsumers: number;
  phase1Consumers: number;
  phase2Consumers: number;
  phase3Consumers: number;
  balancedLoad: Boolean;
  unbalancePercent: number;
  lowLoadedPhase: string;
  consumptionValue1: number;
  consumptionValue1IsDefault: Boolean;
  consumptionValue2: number;
  consumptionValue2IsDefault: Boolean;
  consumptionType: ConsumptionType;
  consumptionTypeIsDefault: Boolean;
  geometry: LatLng;
  maxVoltDropPercent: number | null;
  maxVoltDropPercentPassResult: Boolean;
  maxVoltRiseTotal: number | null;
  maxVoltRiseGeneration: number | null;
  maxVoltRiseUnbalance: number | null;
  numberOfPhases: string;
  numberOfPhasesIsDefault: Boolean;
  parentId: string | null;
  isSubGroupConnection: Boolean;
  isGenerator: Boolean;
  earthLoopImpedance: number;
  threePhaseFaultCurrent: number;
  phaseToPhaseFaultCurrent: number;
  singlePhaseToEarthFaultCurrent: number;
  phaseMap: number[];
  hasFuseWarning: Boolean;
  flickerPassResult: Boolean;
  flickerDeltaVoltage: number;
  flickerP28Limit: number;
  flickerStartingPassResult: Boolean;
  flickerStartingDeltaVoltage: number;
  impedanceLoopOperating: string;
  impedanceLoopOperatingPassResult: Boolean;
  impedanceSourceLoopOperating: string;
  impedanceSourceLoopOperatingPassResult: Boolean;
  impedanceLoopFault: string;
  impedancePhaseOperating: string;
  impedanceSourcePhaseOperating: string;
  impedanceSourcePhasePhaseOperating: string;
  impedancePhaseFault: string;
  resistanceLoopOperating: string;
  resistanceSourceLoopOperating: string;
  resistanceLoopFault: string;
  resistancePhaseOperating: string;
  resistanceSourcePhaseOperating: string;
  resistanceSourcePhasePhaseOperating: string;
  resistancePhaseFault: string;
  reactanceLoopOperating: string;
  reactanceSourceLoopOperating: string;
  reactanceLoopFault: string;
  reactancePhaseOperating: string;
  reactanceSourcePhaseOperating: string;
  reactanceSourcePhasePhaseOperating: string;
  reactancePhaseFault: string;
  AllmaxVoltDropPercent: number | null;
  AllmaxVoltDropPercentPeriod: number | null;
  AllmaxVoltDropPercentPassResult: Boolean;
  AllmaxVoltDropPhase: number | null;
  AllmaxVoltRiseTotal: number | null;
  AllmaxVoltRiseGeneration: number | null;
  AllmaxVoltRiseUnbalance: number | null;
  AllmaxVoltRisePercentPeriod: number | null;
  AllmaxVoltRisePhase: number | null;
  AllmaxVoltage: number | null;
  AllmaxVoltagePhase1: number | null;
  AllmaxVoltagePhase2: number | null;
  AllmaxVoltagePhase3: number | null;
  AllminVoltage: number | null;
  AllminVoltagePhase1: number | null;
  AllminVoltagePhase2: number | null;
  AllminVoltagePhase3: number | null;
  wintermaxVoltDropPercent: number | null;
  wintermaxVoltDropPercentPeriod: number | null;
  wintermaxVoltDropPercentPassResult: Boolean;
  wintermaxVoltDropPhase: number | null;
  wintermaxVoltRisePercent: number | null;
  wintermaxVoltRiseTotal: number | null;
  wintermaxVoltRiseGeneration: number | null;
  wintermaxVoltRiseUnbalance: number | null;
  wintermaxVoltRisePercentPeriod: number | null;
  wintermaxVoltRisePhase: number | null;
  wintermaxVoltage: number | null;
  wintermaxVoltagePhase1: number | null;
  wintermaxVoltagePhase2: number | null;
  wintermaxVoltagePhase3: number | null;
  winterminVoltage: number | null;
  winterminVoltagePhase1: number | null;
  winterminVoltagePhase2: number | null;
  winterminVoltagePhase3: number | null;
  summermaxVoltDropPercent: number | null;
  summermaxVoltDropPercentPeriod: number | null;
  summermaxVoltDropPercentPassResult: Boolean;
  summermaxVoltDropPhase: number | null;
  summermaxVoltRisePercent: number | null;
  summermaxVoltRiseTotal: number | null;
  summermaxVoltRiseGeneration: number | null;
  summermaxVoltRiseUnbalance: number | null;
  summermaxVoltRisePercentPeriod: number | null;
  summermaxVoltRisePhase: number | null;
  summermaxVoltage: number | null;
  summermaxVoltagePhase1: number | null;
  summermaxVoltagePhase2: number | null;
  summermaxVoltagePhase3: number | null;
  summerminVoltage: number | null;
  summerminVoltagePhase1: number | null;
  summerminVoltagePhase2: number | null;
  summerminVoltagePhase3: number | null;
  phaseImpedance: number | null;
  loopImpedance: number | null;
  nodeNumber: number;
  groupId: string | null;
  status: string;
  cutOutSize: string;
  multiOccupancyContainer: any;
  multiOccupancy: Boolean;
  moSpareWays: number;
  linkBox: any;
  potEnds: any;
  overrideGroundType: string;
  pole: any;
  count: number;
  rootTransformerId: string | null;
  substationId: string;
  feederNumber: string | null;
  classIsDefault: Boolean;
  MPAN: string;
  premisesId: string;
}
