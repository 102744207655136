import { LatLngBounds } from "leaflet";
import * as status from "../constants/status";
import { setLocalStorageValue } from "./useLocalStorage";
import { ratingType } from "../app/settingsSlice";
import { CYCLIC, SUSTAINED } from "../constants/ratings";

export const getAssetById = (allAssets, id) => {
  let _asset;

  allAssets.some((assetType) => {
    const asset = assetType.asset;
    if (asset.groupedConnectionPoints && asset.groupedConnectionPoints.length) {
      asset.groupedConnectionPoints.forEach((gcp) => {
        if (gcp.id === id) {
          _asset = gcp;
          return true;
        }
      });
    }

    if (!_asset && asset.id === id) {
      _asset = asset;
      return true;
    }
    return false;
  });

  return _asset;
};

export const resetNetworkToolState = (toolState) => {
  toolState.mode = "select";
  toolState.activeTool = "";
  toolState.activeCategory = 0;
  toolState.clickedAsset = null;
  toolState.drawMarker = false;
  toolState.drawCable = false;
  toolState.drawBoundary = false;
  toolState.ringfenced = [];
  toolState.ringfencedFiltered = [];
  toolState.ringfencedTypes = [];
  toolState.disableBoundary = false;
  toolState.feederList = null;
  toolState.filteredFeeders = {};
  toolState.searchValue = null;
  toolState.matchedSearchIds = [];
  toolState.showResults = false;
  toolState.showNearbyAssets = {
    disabled: false,
    substations: true,
  };
  toolState.selectedAssetId = null;
  toolState.navigateToLatLng = null;
  toolState.selectedTool = [0, 0, 0, 0, 0];
  toolState.keyDown = {};
};

export const resetNetworkElements = () => {
  const displayedInputElement = document.getElementById("importStudyFilename");
  if (displayedInputElement) {
    displayedInputElement.value = "";
  }

  const loadNetworkElement = document.getElementById("loadNetwork");
  if (loadNetworkElement) {
    loadNetworkElement.value = "";
  }

  const locationSearchElement = document.getElementById("location-search");
  if (locationSearchElement) {
    locationSearchElement.value = "";
  }

  const substationSearchElement = document.getElementById("substation-search");
  if (substationSearchElement) {
    substationSearchElement.value = "";
  }
};

export const isRagNetworkPopulated = (ragNetworks) => {
  return ragNetworks && ragNetworks.length;
};

export const isDetectNetworkPopulated = (detectNetworks) => {
  return detectNetworks && detectNetworks.length;
};

export const isVisible = (bounds, geometry) => {
  if (Array.isArray(geometry)) {
    return bounds.intersects(new LatLngBounds(geometry));
  }
  return bounds.contains(geometry);
};

export const networkContainsOverheadLine = (cables) => {
  if (!cables.length) return false;
  return cables.some((c) => c.cableGroup.endsWith("overhead"));
};

export const nodeIsConnectedToOverheadLine = (cables, node, excludedIds) => {
  if (!cables.length) return false;
  return cables.some(
    (c) =>
      c.cableGroup.endsWith("overhead") &&
      (node.id === c.startAssetId || node.id === c.endAssetId) &&
      (!excludedIds || !excludedIds.includes(c.id)),
  );
};

export const nodeIsConnectedToUndergroundLine = (cables, node, excludedIds) => {
  if (!cables.length) return false;
  return cables.some(
    (c) =>
      c.cableGroup.endsWith("underground") &&
      (node.id === c.startAssetId || node.id === c.endAssetId) &&
      (!excludedIds || !excludedIds.includes(c.id)),
  );
};

export const nodeIsConnectedToExistingOverheadLine = (cables, node) => {
  if (!cables.length) return false;
  return cables.some(
    (c) =>
      c.cableGroup.endsWith("overhead") &&
      (node.id === c.startAssetId || node.id === c.endAssetId) &&
      c.status === status.EXISTING,
  );
};

export const getConnectedCablesToNode = (cables, node) => {
  if (!cables.length || !node) return [];
  return cables.filter((c) => node.id === c.startAssetId || node.id === c.endAssetId);
};

export const formatGroupedConnectionsForBoM = (groupedConnections) => {
  // The Calculate API for BoM is expecting subGroupConnectionPoints to be
  // a List<string>, but this app requires it to be a type of ConnectionPoint.
  // We take a copy of connectionGroup and change the subGroupConnectionPoints
  // to be a list of ids, then return the re-formatted groupedConnections...
  let bomGroupedConnections = JSON.parse(JSON.stringify(groupedConnections));

  bomGroupedConnections.forEach((gc) => {
    gc.groupedConnectionPoints.forEach((gcp) => {
      let subGroupConnectionsArray = [];
      gcp.subGroupConnectionPoints.forEach((sub) => {
        subGroupConnectionsArray.push(sub.id);
      });
      gcp.subGroupConnectionPoints = subGroupConnectionsArray;
    });
  });

  return bomGroupedConnections;
};

export const centreNetworkOnMap = async (network, allGeometries, leafletMap) => {
  if (allGeometries.length > 0) {
    const networkObjs = [];
    Object.entries(network)
      .filter((f) => Array.isArray(f[1]))
      .forEach(([assetType, assets]) => {
        assets.forEach((asset) => {
          if (Array.isArray(asset.geometry)) {
            asset.geometry.forEach((geometry) => {
              networkObjs.push(geometry);
            });
          } else {
            networkObjs.push(asset.geometry);
          }
        });
      });

    const allNetworkObjs = networkObjs.concat(allGeometries);

    allNetworkObjs.length > 0 && leafletMap.fitBounds(allNetworkObjs);
    allNetworkObjs.length <= 2 && leafletMap.setZoom(1);
  }
};

export const isWelder = (asset) => {
  return asset.styles.name.toLowerCase() === "welder";
};

export const isMotor = (asset) => {
  return asset.styles.name.toLowerCase() === "motor";
};

export const isTransformer = (asset) => {
  return asset.styles.type.toLowerCase() === "transformer";
};

export const isNode = (asset) => {
  return (
    asset.styles.type.toLowerCase() === "groupedconnection" &&
    asset.groupedConnectionPoints?.length === 0
  );
};

export const isPointOfConnection = (asset) => {
  return asset.styles.class.toLowerCase() === "point-of-connection";
};

export const isConnectionPoint = (asset) => {
  return (
    (asset.styles.type.toLowerCase() === "connectionpoint" ||
      asset.styles.type.toLowerCase() === "groupedconnection") &&
    asset.groupedConnectionPoints?.length > 0
  );
};

export const isCable = (asset) => {
  return asset.styles.type.toLowerCase() === "cable";
};

export const canRemoveNetworkAsset = (cables, assetId, assetType) => {
  switch (assetType) {
    case "welders":
    case "motors":
    case "transformers":
      return !cables.some((c) => c.startAssetId === assetId || c.endAssetId === assetId);
    default:
      return true;
  }
};

export const doesAssetContainStatusObjects = (asset) => {
  return (
    !!asset.linkBox ||
    !!asset.potEnds?.length ||
    !!asset.pole ||
    !!asset.groupedConnectionPoints?.length
  );
};

export const doesAssetJoinCableHavingStatus = (asset, cables, expectedStatus) => {
  return cables.some(
    (c) =>
      (c.startAssetId === asset.id || c.endAssetId === asset.id) && c.status === expectedStatus,
  );
};

export const doesAssetHaveStatus = (asset, expectedStatus) => {
  if (!asset) {
    return false;
  }

  const hasNestedStatus = (asset, expectedStatus) => {
    if (!asset.subGroupConnectionPoints || asset.subGroupConnectionPoints.length === 0) {
      return false;
    }
    return asset.subGroupConnectionPoints.some(
      (p) => p.status === expectedStatus || hasNestedStatus(p, expectedStatus),
    );
  };

  if (
    asset.linkBox?.status === expectedStatus ||
    asset.potEnds?.some((p) => p.status === expectedStatus) === true ||
    asset.pole?.status === expectedStatus
  )
    return true;

  if (
    asset.groupedConnectionPoints?.some(
      (p) => p.status === expectedStatus || hasNestedStatus(p, expectedStatus),
    )
  ) {
    return true;
  }

  return hasNestedStatus(asset, expectedStatus);
};

export const getTransformerPhase = (formState, transformers, cables, groupedConnectionId) => {
  let foundTransformer;

  if (groupedConnectionId && cables) {
    const processedIds = [];

    const walk = (currentId) => {
      if (foundTransformer) {
        return;
      }
      const transformer = transformers.find((p) => p.id === currentId);
      if (transformer) {
        foundTransformer = transformer;
        return;
      }
      if (processedIds.includes(currentId)) {
        return;
      }
      processedIds.push(currentId);

      cables
        .filter((p) => p.startAssetId === currentId || p.endAssetId === currentId)
        .forEach((p) => walkCable(p));
    };

    const walkCable = (cable) => {
      walk(cable.startAssetId);
      walk(cable.endAssetId);
    };

    walk(groupedConnectionId);
  }

  let transformerPhase =
    foundTransformer?.numberOfPhases ?? formState.transformerProperties.numberOfPhases;

  if (!transformerPhase && transformers.length > 0) {
    transformerPhase = transformers[0].numberOfPhases;
  }

  if (!transformerPhase) {
    transformerPhase = "Three";
  }

  switch (transformerPhase) {
    case "Split":
      return "Two";
    default:
      return transformerPhase;
  }
};

export const getNumberOfPhasesForTransformerType = (
  transformers,
  reference,
  clickedAsset,
  mounting,
) => {
  const numberOfPhases =
    transformers.find((p) => p.id === clickedAsset.id)?.numberOfPhases ?? "Three";

  if (
    reference.transformers.types.every(
      (f) =>
        !(
          f[numberOfPhases.toLowerCase() + "phase"] &&
          f[numberOfPhases.toLowerCase() + "phase"][mounting]
        ),
    )
  ) {
    return "Three";
  }

  return numberOfPhases;
};

export const detectAndConfigureRating = (network, dispatch, forceRating = undefined) => {
  if (forceRating) {
    dispatch(ratingType(forceRating));
    setLocalStorageValue("ratingType", forceRating);
    return;
  }

  if (!network?.cables || network.cables?.length === 0) {
    return;
  }

  const hasSustained = network.cables.some((p) => p.ratingType === SUSTAINED);
  const hasCyclic = network.cables.some((p) => p.ratingType === CYCLIC);

  if (hasSustained && hasCyclic) {
    // Mix of ratings, keep current selection
    return;
  }

  dispatch(ratingType(hasCyclic ? CYCLIC : SUSTAINED));
  setLocalStorageValue("ratingType", hasCyclic ? CYCLIC : SUSTAINED);
};
