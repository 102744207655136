import React, { useState, useContext, useEffect } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  Button,
  Col,
  Row,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

import { FormContext } from "../context/FormContext";
import { ToolContext } from "../context/ToolContext";
import ConnectionForm from "./ConnectionForm";
import RadioBtnGrp from "./RadioBtnGrp";
import DataAlert from "./DataAlert";
import GroundTypeOverrideSelector from "./GroundTypeOverrideSelector";
import { useSelector, useDispatch } from "react-redux";
import * as statusConstants from "../constants/status";
import { formatLength } from "../utils/numberFunctions";
import { getAssetById } from "../utils/networkFunctions";
import EATooltip from "./EATooltip";
import { PromptInput } from "./PromptInput";
import {
  clearResults,
  findAll,
  updateCable,
  updateCableArray,
  updateGroupedConnectionPole,
  updateTransformer,
} from "../app/networkSlice";
import { getResultProperties } from "../utils/referenceFunctions";
import { store } from "../app/store";
import { endBatchAction, endSkipAction, startBatchAction, startSkipAction } from "../app/undoable";
import DynamicPropertiesList from "../components/DynamicPropertiesList";

const phasesObj = {
  name: "phases",
  obj: [
    { id: "cableSinglePhase", value: "Single", color: "light" },
    { id: "cableTwoPhase", value: "Two", color: "light" },
    { id: "cableThreePhase", value: "Three", color: "light" },
  ],
};

const CABLEFORM = "CableForm";

const CableForm = ({ cable }) => {
  const { formState, dispatch } = useContext(FormContext);
  const { clientSettings, reference } = formState;
  const poleTermLengthEnabled = clientSettings.features.PoleTermLengthEnabled;
  const {
    cableGroup,
    cableGroupIsDefault,
    numberOfPhases,
    numberOfPhasesIsDefault,
    numberOfPhasesOriginal,
    cableType,
    cableTypeIsDefault,
    cableTypeOriginal,
    length,
    overrideLength,
    autoSelect,
    ducting,
    annotation,
    status,
    groundTypeOverridesIsDefault,
  } = cable;

  const { toolState, setToolState } = useContext(ToolContext);
  const ratingType = useSelector((state) => state.settings.ratingType);
  const allAssets = useSelector((state) => findAll(state));
  const groupedConnections = useSelector((state) => state.network.present.groupedConnections);

  const dispatchRedux = useDispatch();

  const OVERHEAD = "overhead";

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
    setInitialFormat(false);
  }, []);

  const enableOverride = overrideLength > 0;
  const [enableOverrideLength, setEnableOverrideLength] = useState(enableOverride);
  const [initialFormat, setInitialFormat] = useState(true);

  useEffect(() => {
    setEnableOverrideLength(enableOverride);
  }, [enableOverride]);

  const handleChangeAnnotation = (e) => {
    changeInputValue(e);
  };

  const { errors, clickedAsset, activeTool } = toolState;

  const defaultsArr = [
    { name: numberOfPhasesIsDefault, label: "numberOfPhases" },
    { name: cableTypeIsDefault, label: "cableType" },
    { name: cableGroupIsDefault, label: "cableGroup" },
    { name: groundTypeOverridesIsDefault, label: "groundTypeOverrides" },
  ];

  const handleChangedStatus = (value) => {
    if ((value === statusConstants.NEW || value === statusConstants.REPLACEMENT) && clickedAsset) {
      dispatchRedux(
        updateCable({
          id: clickedAsset.id,
          name: "length",
          value: Number(clickedAsset.length).toFixed(2),
        }),
      );

      if (clickedAsset.overrideLength?.toString() === "0") {
        dispatchRedux(updateCable({ id: clickedAsset.id, name: "overrideLength", value: null }));
      }

      if (!clickedAsset.groundTypeOverrides) {
        dispatchRedux(
          updateCable({
            id: clickedAsset.id,
            name: "groundTypeOverrides",
            value: [
              {
                groundType: reference.groundTypeOverrideDefaults.groundTypeOverride,
                length: clickedAsset.length,
              },
            ],
          }),
        );
      }
    }
  };

  const changeInputValue = (e) => {
    const name = e.name ? e.name : e.target.name;
    const value = e.value !== undefined ? e.value : e.target.value;

    store.dispatch(startBatchAction());
    try {
      handleDefault(name);
      handleChangedStatus(value);
      dispatch({
        form: "cableProperties",
        field: name,
        value: value,
        type: "UPDATE_FIELD",
      });
      if (clickedAsset && clickedAsset.styles.type === "cable") {
        if (clickedAsset.groundTypeOverrides) {
          if (name === "overrideLength" && clickedAsset.groundTypeOverrides.length === 1) {
            dispatch({
              form: "cableProperties",
              field: "groundTypeOverrides",
              value: [
                ...clickedAsset.groundTypeOverrides.map((p, i) =>
                  i === 0 ? { ...p, length: value } : p,
                ),
              ],
              type: "UPDATE_FIELD",
            });
            dispatchRedux(
              updateCableArray({
                id: clickedAsset.id,
                arrayProperty: "groundTypeOverrides",
                index: 0,
                name: "length",
                value,
              }),
            );
          }
        }

        const filteredErrors = errors.messages
          ? errors.messages.map((message) => message.link)
          : [];

        if (filteredErrors.includes(clickedAsset.id)) {
          let _errors = { ...errors };
          _errors.messages = _errors.messages
            ? _errors.messages.filter((message) => message.link !== clickedAsset.id)
            : [];

          const _toolState = toolState;
          _toolState.errors = _errors;
          setToolState(_toolState);
        }

        dispatchRedux(updateCable({ id: clickedAsset.id, name, value }));

        if (name === "status" && cable.cableGroup === "mains-overhead") {
          updateConnectedPole(value);
        }
      }
    } finally {
      store.dispatch(endBatchAction());
    }
  };

  const changeCableType = (e) => {
    hideResults();
    const cable = e.target.value;

    store.dispatch(startBatchAction());
    try {
      changeInputValue(e);
      changeInputValue({
        name: "cableType",
        value: formState.reference.cables.defaults[cable],
      });
    } finally {
      store.dispatch(endBatchAction());
    }
  };

  const setSelectedPhase_ = (e) => {
    hideResults();
    changeInputValue({ name: "numberOfPhases", value: e.value });
  };

  const hideResults = () => {
    const _toolState = toolState;
    _toolState.showResults = false;
    setToolState(_toolState);
    dispatchRedux(clearResults(getResultProperties(clientSettings)));
  };

  const handleChange = (e) => {
    if (e.name !== "autoSelect") e.preventDefault();
    hideResults();
    changeInputValue(e);
  };

  const formatOverrideLengthPrecision = (value) => {
    var parsedValue = parseFloat(value);
    if (parsedValue > 0 && parsedValue < 0.01) {
      return parsedValue.toFixed(10).replace(/\.?0+$/, "");
    }
    return parsedValue.toFixed(2);
  };

  const formatOverrideLength = (e) => {
    const value = e?.target?.value;

    store.dispatch(startSkipAction());
    try {
      if (value === "") {
        toggleOverrideLength();
      } else {
        e.target.value = formatOverrideLengthPrecision(value);
        changeInputValue(e);
      }
    } finally {
      store.dispatch(endSkipAction());
    }
  };

  const handleChange2 = (e) => {
    store.dispatch(startBatchAction());
    try {
      handleChange(e);
      const autoSelect = {
        name: "autoSelect",
        value:
          e.target.value === "auto" ||
          (e.target.name === "status" &&
            (e.target.value === statusConstants.NEW ||
              e.target.value === statusConstants.REPLACEMENT) &&
            !isCableTypeInWhiteList()),
      };
      handleChange(autoSelect);
      if (autoSelect.value === true) {
        const cableType = {
          name: "cableType",
          value: "auto",
        };
        hideResults();
        changeInputValue(cableType);
      }
    } finally {
      store.dispatch(endBatchAction());
    }
  };

  const handleDucting = () => {
    hideResults();
    changeInputValue({
      name: "ducting",
      value: !ducting,
    });
  };

  const toggleOverrideLength = () => {
    hideResults();
    setEnableOverrideLength(!enableOverrideLength);
    store.dispatch(startBatchAction());
    try {
      changeInputValue({
        name: "overrideLength",
        value: "",
      });
      if (enableOverrideLength) {
        if (clickedAsset.groundTypeOverrides.length === 1) {
          dispatch({
            form: "cableProperties",
            field: "groundTypeOverrides",
            value: [
              ...clickedAsset.groundTypeOverrides.map((p, i) =>
                i === 0 ? { ...p, length: length } : p,
              ),
            ],
            type: "UPDATE_FIELD",
          });
          dispatchRedux(
            updateCableArray({
              id: clickedAsset.id,
              arrayProperty: "groundTypeOverrides",
              index: 0,
              name: "length",
              length,
            }),
          );
        }
      }
    } finally {
      store.dispatch(endBatchAction());
    }
  };

  const handleDefault = (e) => {
    if (defaultsArr.some((d) => d.label === e)) {
      dispatch({
        form: "cableProperties",
        field: `${e}IsDefault`,
        value: false,
        type: "UPDATE_FIELD",
      });
      if (clickedAsset && clickedAsset.styles.type === "cable") {
        dispatchRedux(updateCable({ id: clickedAsset.id, name: `${e}IsDefault`, value: false }));
      }
    }
  };

  const handleAllDefaults = () => {
    store.dispatch(startBatchAction());
    try {
      defaultsArr.forEach((d) => {
        if (d.name) handleDefault(d.label);
      });
    } finally {
      store.dispatch(endBatchAction());
    }
  };

  const isCableTypeInWhiteList = () => {
    const checkingForAutoCable = true;
    const cableTypes = getCableTypes(checkingForAutoCable);
    return cableTypes.find((c) => c.name === cableType);
  };

  const getCableTypes = (checkingForAutoCable) => {
    if (
      (status === statusConstants.NEW ||
        status === statusConstants.REPLACEMENT ||
        checkingForAutoCable) &&
      clientSettings.features.NewExistingStatusEnabled
    ) {
      let whitelist;
      if (cableGroup.split("-")[1] === OVERHEAD) {
        whitelist = formState.reference.newOHCableTypeWhiteList?.split(",").map((x) => {
          return { name: x };
        });
      } else {
        whitelist = formState.reference.newUGCableTypeWhiteList?.split(",").map((x) => {
          return { name: x };
        });
      }
      return whitelist;
    }

    return formState.reference.cables.types.filter(
      (f) => f[cableGroup.split("-")[0]] && f[cableGroup.split("-")[1]],
    );
  };

  const additionalLength = () => {
    if (!clickedAsset || !cable || !cableGroup.includes("underground")) {
      return 0;
    }

    const start = getAssetById(allAssets, cable.startAssetId);
    const end = getAssetById(allAssets, cable.endAssetId);

    let total = 0;

    if (start?.mounting === "pmt") {
      total += parseFloat(start.poleTermLength);
    } else if (start?.pole?.enabled) {
      total += parseFloat(start.pole.poleTermLength ?? 0);
    }

    if (end?.mounting === "pmt") {
      total += parseFloat(end.poleTermLength);
    } else if (end?.pole?.enabled) {
      total += parseFloat(end.pole.poleTermLength ?? 0);
    }

    return total;
  };

  const removePoleTermLength = () => {
    const start = getAssetById(allAssets, cable.startAssetId);
    const end = getAssetById(allAssets, cable.endAssetId);

    store.dispatch(startBatchAction());
    let hasUpdate = false;
    try {
      if (start?.pole?.poleTermLength > 0) {
        dispatchRedux(
          updateGroupedConnectionPole({
            id: start.id,
            name: "poleTermLength",
            value: formatLength(0),
          }),
        );
        hasUpdate = true;
      } else if (start?.poleTermLength > 0) {
        dispatchRedux(
          updateTransformer({ id: start.id, name: "poleTermLength", value: formatLength(0) }),
        );
        hasUpdate = true;
      }

      if (end?.pole?.poleTermLength > 0) {
        dispatchRedux(
          updateGroupedConnectionPole({
            id: end.id,
            name: "poleTermLength",
            value: formatLength(0),
          }),
        );
        hasUpdate = true;
      } else if (end?.poleTermLength > 0) {
        dispatchRedux(
          updateTransformer({ id: end.id, name: "poleTermLength", value: formatLength(0) }),
        );
        hasUpdate = true;
      }
    } finally {
      store.dispatch(endBatchAction());
    }

    if (hasUpdate) {
      hideResults();

      const _formState = { ...formState };
      _formState.network.existing = false;
    }
  };

  const shouldShowAdditionalLengthFlag = () => {
    return poleTermLengthEnabled && additionalLength() > 0;
  };

  const shouldShowGroundTypeOverride = () => {
    if (!clickedAsset || !cable || !cable.length) {
      return false;
    }

    if (status !== statusConstants.NEW && status !== statusConstants.REPLACEMENT) {
      return false;
    }

    return cableGroup.includes("underground");
  };

  const getOverrideLength = () => {
    if (initialFormat || (overrideLength > 0 && overrideLength < 0.01)) {
      return formatOverrideLengthPrecision(overrideLength);
    }
    return overrideLength;
  };

  const updateConnectedPole = (value) => {
    const connectedPoles = groupedConnections.filter(
      (gc) => (gc.id === cable.startAssetId && gc.pole) || (gc.id === cable.endAssetId && gc.pole),
    );

    connectedPoles.forEach((connectedPole) => {
      const pole = { ...connectedPole.pole };
      pole.status = value;

      dispatch({
        form: "groupedConnectionProperties",
        field: "pole",
        value: pole,
        type: "UPDATE_FIELD",
      });
      dispatchRedux(
        updateGroupedConnectionPole({ id: connectedPole.id, name: "status", value: status }),
      );
    });
  };

  const getCableRatings = (cableType) => {
    return formState.reference.cables.types.find((f) => f.name === cableType)?.ratings;
  };

  const updateRatingWithDucting = (value) => {
    if (!ducting || !reference.constraints.ductedCableFactor) {
      return value;
    }
    return Math.round(value * reference.constraints.ductedCableFactor);
  };

  return (
    <Form
      className="w-100 text-light"
      style={{
        padding: ".75rem",
      }}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      {defaultsArr.find((d) => d.name === true) && (
        <Button color="warning" outline block className="mb-3" onClick={() => handleAllDefaults()}>
          Resolve all default warnings
        </Button>
      )}
      <FormGroup>
        <PromptInput
          id="cableAnnotation"
          name="annotation"
          className="w-100"
          placeholder="Enter label/annotation"
          value={annotation}
          onChange={handleChangeAnnotation}
          maxLength="50"
        />
      </FormGroup>
      {clientSettings.features.DebugEnabled && (
        <div>
          {clickedAsset && clickedAsset.styles.type === "cable" && clickedAsset.wpdId && (
            <div className="no-gutters row p-3 mb-1 bg-dark">
              <div className="col-4">WPD Id:</div>
              <div className="col">{clickedAsset.wpdId}</div>
            </div>
          )}
          {clickedAsset && clickedAsset.styles.type === "cable" && clickedAsset.id && (
            <div className="no-gutters row p-3 mb-1 bg-dark">
              <div className="col-4">Asset Id:</div>
              <div className="col">{clickedAsset.id}</div>
            </div>
          )}
          {clickedAsset && clickedAsset.styles.type === "cable" && clickedAsset.startAssetId && (
            <div className="no-gutters row p-3 mb-1 bg-dark">
              <div className="col-4">Start Asset Id:</div>
              <div className="col">{clickedAsset.startAssetId}</div>
            </div>
          )}

          {clickedAsset && clickedAsset.styles.type === "cable" && clickedAsset.endAssetId && (
            <div className="no-gutters row p-3 mb-1 bg-dark">
              <div className="col-4">End Asset Id:</div>
              <div className="col">{clickedAsset.endAssetId}</div>
            </div>
          )}

          {clickedAsset && clickedAsset.styles.type === "cable" && clickedAsset.length && (
            <div className="no-gutters row p-3 mb-1 bg-dark">
              <div className="col-4">Length:</div>
              <div className="col">{clickedAsset.length}</div>
            </div>
          )}

          {clickedAsset && clickedAsset.styles.type === "cable" && clickedAsset.length && (
            <div className="no-gutters row p-3 mb-1 bg-dark">
              <div className="col-4">Near Node:</div>
              <div className="col">{clickedAsset.nearNodeNumber}</div>
            </div>
          )}

          {clickedAsset && clickedAsset.styles.type === "cable" && clickedAsset.length && (
            <div className="no-gutters row p-3 mb-1 bg-dark">
              <div className="col-4">Far Node:</div>
              <div className="col">{clickedAsset.farNodeNumber}</div>
            </div>
          )}
        </div>
      )}

      {clickedAsset && clickedAsset.styles.type === "cable" && (
        <span className="cableId" style={{ position: "absolute", visibility: "hidden" }}>
          {clickedAsset.id}
        </span>
      )}
      {clickedAsset && clickedAsset.feederNumber && clientSettings.dynamicProperties.length < 1 && (
        <FormGroup>
          <Label className="id">ID: {clickedAsset.feederNumber}</Label>
        </FormGroup>
      )}
      {clickedAsset && clientSettings.features.DynamicPropertiesEnabled && (
        <DynamicPropertiesList propertyType={"ACLineSegment"} />
      )}

      <FormGroup>
        <Label for="cableGroup">
          Cable Group
          {cableGroupIsDefault && <DataAlert updateDefault={() => handleDefault("cableGroup")} />}
        </Label>
        <Input
          type="select"
          name="cableGroup"
          id="cableGroup"
          value={cableGroup}
          onChange={changeCableType}
        >
          <option value={`mains-${toolState.activeTool.class.split("-")[0]}`}>Mains</option>
          <option value={`service-${toolState.activeTool.class.split("-")[0]}`}>Service</option>
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="phases">
          No. of Phases
          {numberOfPhasesIsDefault && (
            <DataAlert
              updateDefault={() => handleDefault("numberOfPhases")}
              original={numberOfPhasesOriginal}
            />
          )}
        </Label>
        <RadioBtnGrp
          items={phasesObj}
          changeValue={setSelectedPhase_}
          selectedValue={numberOfPhases}
        />
      </FormGroup>
      {clientSettings.features.NewExistingStatusEnabled && (
        <FormGroup>
          <Label for="status">New / Existing / Replacement</Label>
          <Input type="select" name="status" id="status" value={status} onChange={handleChange2}>
            <option value={statusConstants.NEW}>New</option>
            <option value={statusConstants.EXISTING}>Existing</option>
            <option value={statusConstants.REPLACEMENT}>Replacement</option>
          </Input>
        </FormGroup>
      )}
      <FormGroup>
        <Label for="cableType">
          Cable Type
          {cableTypeIsDefault && (
            <DataAlert
              updateDefault={() => handleDefault("cableType")}
              original={cableTypeOriginal}
            />
          )}
        </Label>
        <Input
          type="select"
          name="cableType"
          id="cableType"
          value={autoSelect ? "auto" : cableType}
          onChange={handleChange2}
        >
          <option value="auto">Auto</option>
          {cableGroup &&
            getCableTypes().map((type) => (
              <option key={type.name} value={type.name}>
                {type.name}
              </option>
            ))}
        </Input>
      </FormGroup>
      <FormGroup row className="no-gutters">
        <Col sm={12}>
          <Label for="length">Length (m)</Label>
          <InputGroup>
            {enableOverrideLength ? (
              <Input
                type="number"
                id="overrideLength"
                name="overrideLength"
                className="w-50 no-spinner"
                placeholder="0"
                value={getOverrideLength()}
                onChange={handleChange}
                onBlur={formatOverrideLength}
                autoFocus
              />
            ) : (
              <Input
                type="number"
                className="no-spinner"
                id="length"
                name="length"
                placeholder="0"
                value={parseFloat(length).toFixed(2)}
                onClick={toggleOverrideLength}
                readOnly
              />
            )}
            {shouldShowAdditionalLengthFlag() && (
              <InputGroupAddon addonType="append">
                <>
                  <EATooltip
                    text="Additional length will be added for pole term(s)"
                    target="additional-length-icon"
                    placement="top"
                  />
                  <span id="additional-length-icon" className="btn btn-dark">
                    <i className={`icon-circle-dot`}></i>
                  </span>
                </>
              </InputGroupAddon>
            )}
            <InputGroupAddon addonType="append">
              <Button color="dark" onClick={toggleOverrideLength}>
                <i className={`icon-${enableOverrideLength ? "lock" : "lock-open"}-solid`}></i>
              </Button>
            </InputGroupAddon>
          </InputGroup>
          <small>
            {enableOverrideLength
              ? "Manually input length"
              : length < 1
                ? "Awaiting route..."
                : "Calculated length"}
          </small>
        </Col>
      </FormGroup>
      {cableType !== "auto" && (
        <FormGroup>
          <label>Rating - {ratingType}</label>
          {getCableRatings(cableType) ? (
            <>
              <h6>
                {getCableRatings(cableType).profiles.map((m) => (
                  <span key={m.name} className="mx-1">
                    <i
                      className={`icon-${
                        m.name === "summer" ? "sun" : "snowflake"
                      }-solid text-${m.name === "summer" ? "lemon" : "duck-egg"}`}
                    ></i>{" "}
                    {updateRatingWithDucting(
                      m.type.find((r) => r.name === ratingType.toLowerCase()).value,
                    )}
                    A
                  </span>
                ))}
              </h6>
            </>
          ) : (
            <div>
              <label>
                <i className="text-warning icon-circle-solid mx-2"></i>
                {`${cableType} not found`}
              </label>
            </div>
          )}
        </FormGroup>
      )}
      {activeTool.id === "UndergroundCable" && (
        <FormGroup>
          <Button
            color="dark"
            block
            onClick={() => handleDucting()}
            className={ducting ? "active" : ""}
          >
            <div className="d-flex justify-content-between align-items-center">
              <span>Ducting</span>
              <FontAwesomeIcon icon={ducting ? faCheck : faTimes} />
            </div>
          </Button>
        </FormGroup>
      )}
      {shouldShowGroundTypeOverride() && clientSettings.features.CostingEnabled && (
        <GroundTypeOverrideSelector
          overrideLength={enableOverrideLength ? overrideLength : length}
          additionalLength={additionalLength()}
          removePoleTermLength={removePoleTermLength}
          groundTypeOverridesIsDefault={groundTypeOverridesIsDefault}
          handleDefault={handleDefault}
        />
      )}
      <Row>
        <Col>
          <Label for="distributeload">Distributed Connections</Label>
          <ConnectionForm Item={CABLEFORM} />
        </Col>
      </Row>
    </Form>
  );
};

export default CableForm;
