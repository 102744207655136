import { useContext } from "react";
import { Container, Table } from "reactstrap";
import { FormContext } from "../context/FormContext";
import ReportTable from "./ReportTable";
import ReportTableSummary from "./ReportTableSummary";
import { parseReportResults } from "../utils/parseReportResults";
import { groupBy } from "../utils/groupBy";
import { getAssetIdsOfTeesAndEndpoints } from "../utils/cableFunctions";
import {
  applyCableAutoSelectOverride,
  applyConstraintsOverride,
  applyTransformerAutoSelectOverride,
} from "../utils/configurationFunctions";

import "./ResultReport.css";

export const getSteadyVoltagePassResult = (asset) => {
  return asset && asset.flickerPassResult ? "Pass" : "Refer";
};

export const getStartingVoltagePassResult = (asset) => {
  return asset && asset.flickerStartingPassResult ? "Pass" : "Refer";
};

const ResultReport = ({
  reportId,
  networkTransformers,
  networkPointOfConnections,
  networkGroupedConnections,
  networkCables,
  networkMotors,
  networkWelders,
}) => {
  const { formState } = useContext(FormContext);
  const { reference, localOverrideConfig, billOfMaterials, clientSettings } = formState;
  const { AssessmentResultsLimitedEnabled } = clientSettings.features;

  const { REACT_APP_THEME } = process.env;
  const overrideConstraints = applyConstraintsOverride(reference.constraints, localOverrideConfig);

  const findNodeNumber = (list, transformerId, nodeNumber) => {
    for (const item of list) {
      if (!item.relatedNodeNumbers) {
        continue;
      }
      const match = item.relatedNodeNumbers.nodeNumbers.find(
        (p) => p.transformerId === transformerId && p.nodeNumber === nodeNumber,
      );
      if (match) {
        return item;
      }
    }
    return null;
  };

  const getFeeders = (reportId) => {
    let feederAssets = [];

    let filteredNetworkCables = networkCables[reportId];
    if (AssessmentResultsLimitedEnabled && filteredNetworkCables) {
      const teesAndEndpoints = getAssetIdsOfTeesAndEndpoints(filteredNetworkCables);
      filteredNetworkCables = filteredNetworkCables.filter((cable) =>
        teesAndEndpoints.includes(cable.endAssetId),
      );
    }

    filteredNetworkCables?.forEach((cable) => {
      let nearNode = null;
      let farNode = null;
      Object.values({
        transformers: networkTransformers[reportId],
        networkPointOfConnections: networkPointOfConnections[reportId],
        groupedConnections: networkGroupedConnections[reportId],
        welders: networkWelders[reportId],
        motors: networkMotors[reportId],
      }).forEach((assets) => {
        if (Array.isArray(assets)) {
          assets.forEach((asset) => {
            if (asset.nodeNumber) {
              if (asset.nodeNumber === cable.nearNodeNumber) {
                nearNode = asset;
              }
              if (asset.nodeNumber === cable.farNodeNumber) {
                farNode = asset;
              }
            }
          });
        }
      });
      if (!nearNode) {
        Object.keys(networkGroupedConnections).forEach((key) => {
          if (!nearNode && Array.isArray(networkGroupedConnections[key])) {
            nearNode = {
              ...findNodeNumber(networkGroupedConnections[key], reportId, cable.nearNodeNumber),
              nodeNumber: cable.nearNodeNumber,
            };
          }
        });
      }
      if (!farNode) {
        Object.keys(networkGroupedConnections).forEach((key) => {
          if (!farNode && Array.isArray(networkGroupedConnections[key])) {
            farNode = {
              ...findNodeNumber(networkGroupedConnections[key], reportId, cable.farNodeNumber),
              nodeNumber: cable.farNodeNumber,
            };
          }
        });
      }

      if (nearNode && farNode) {
        feederAssets.push({
          feederNumber: cable.feederNumber,
          cable: cable,
          nearNode: nearNode,
          farNode: farNode,
          cableResults: parseReportResults(cable, clientSettings),
          farNodeResults: parseReportResults(farNode, clientSettings),
        });
      }
    });
    return groupBy(feederAssets, "feederNumber");
  };

  const getFlicker = (reportId) => {
    let flickerAssets = [];
    networkMotors[reportId] &&
      networkMotors[reportId].forEach((motor) => {
        flickerAssets.push({
          asset: motor,
          results: parseReportResults(motor, clientSettings),
        });
      });
    networkWelders[reportId] &&
      networkWelders[reportId].forEach((welder) => {
        flickerAssets.push({
          asset: welder,
          results: parseReportResults(welder, clientSettings),
        });
      });
    return flickerAssets;
  };

  const getBomCables = (reportId) => {
    let bomCables = [];
    networkCables[reportId].forEach((cable) => {
      var found = bomCables.some((bomCable) =>
        cable.cableType === "auto"
          ? bomCable.cableType === cable.cableTypeAssigned
          : bomCable.cableType === cable.cableType,
      );
      if (!found) bomCables.push(JSON.parse(JSON.stringify(cable)));
      else {
        var objIndex = bomCables.findIndex((bomCable) =>
          cable.cableType === "auto"
            ? bomCable.cableType === cable.cableTypeAssigned
            : bomCable.cableType === cable.cableType,
        );

        var length = (
          (parseFloat(bomCables[objIndex].overrideLength)
            ? parseFloat(bomCables[objIndex].overrideLength)
            : parseFloat(bomCables[objIndex].length)) +
          (parseFloat(cable.overrideLength)
            ? parseFloat(cable.overrideLength)
            : parseFloat(cable.length))
        ).toFixed(2);

        if (parseFloat(bomCables[objIndex].overrideLength)) {
          bomCables[objIndex].overrideLength = length;
        } else {
          bomCables[objIndex].length = length;
        }
      }
    });
    return bomCables;
  };

  const getTransformers = (reportId) => {
    let transformerAssets = [];
    networkTransformers[reportId] &&
      networkTransformers[reportId].forEach((transformer) => {
        transformerAssets.push({
          asset: transformer,
          results: parseReportResults(transformer, clientSettings),
        });
      });
    return transformerAssets;
  };

  const getTransformer = (reportId) => {
    const firstTransformer = networkTransformers[reportId][0];

    if (!firstTransformer) {
      return undefined;
    }

    return {
      phaseAngle: firstTransformer["phaseAngle"],
      phasesAvailable: firstTransformer["phasesAvailable"],
    };
  };

  const getMaximumSeason = (asset, resultName) => {
    if (asset) {
      const summerResult = parseFloat(asset[`summer${resultName}`]);
      const winterResult = parseFloat(asset[`winter${resultName}`]);
      if (summerResult > winterResult) {
        return "Summer";
      }
      if (summerResult < winterResult) {
        return "Winter";
      }
    }
    return "All";
  };

  const getConstraint = (category, name, option = "") => {
    const result = clientSettings.filterResults
      .find((f) => f.category === category)
      .info.find((i) => i.name === name);

    return option ? result.options.find((i) => i.name === option).constraint : result.constraint;
  };

  const showAlert = (asset, faultName, results = "results") => {
    const fault = asset[results].find((r) => r.category === faultName);
    return fault && fault.constraint ? "alert alert-danger" : "";
  };

  const hasAlertFlag = (asset, faultName, results) => {
    return asset[results] && asset[results][faultName];
  };

  const showAlerts = (asset, alertDetails) => {
    const hasAlert = alertDetails.some((p) => {
      if (p.constraint) {
        return showAlert(asset, p.constraint, p.tab);
      }
      return hasAlertFlag(asset, p.flag, p.tab);
    });
    return hasAlert ? "alert alert-danger" : "";
  };

  const getReportLabel = (tab) => {
    const v = networkTransformers[tab];
    const i = Object.keys(networkTransformers).indexOf(tab);
    return v[0].annotation || v[0].substationId || `Transformer ${i + 1}`;
  };

  return (
    <>
      <Container className="report pagebreak">
        <h3 className="print-report mb-4" style={{ paddingLeft: "0.3rem" }}>
          {getReportLabel(reportId)}
        </h3>
        <ReportTableSummary
          header="Summary of Connections"
          network={{
            transformers: networkTransformers[reportId],
            cables: networkCables[reportId],
            groupedConnections: networkGroupedConnections[reportId],
            welders: networkWelders[reportId],
            motors: networkMotors[reportId],
          }}
          theme={REACT_APP_THEME}
        />
        <h5 style={{ paddingLeft: ".3rem" }}>Transformers</h5>
        <Table
          style={{ marginBottom: "3rem" }}
          className={`table-sm table-custom table-custom-${REACT_APP_THEME}`}
        >
          <thead>
            <tr className="mb-4">
              <th>Tx Number</th>
              <th>Type</th>
              <th>No Phases</th>
              <th>Auto</th>
              <th>Rating (kVA)</th>
              <th>Design Voltage (V)</th>
              {!AssessmentResultsLimitedEnabled && (
                <>
                  <th>HV Fault Level</th>
                  <th>HV X/R</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.values(networkTransformers[reportId]).map((transformer) => (
              <tr key={transformer.id}>
                <td>{transformer.nodeNumber}</td>
                <td>{transformer.mounting}</td>
                <td>{transformer.numberOfPhases}</td>
                <td>{transformer.autoSelect && transformer.autoSelect.toString()}</td>
                <td>
                  {transformer.nameplateRating === "auto"
                    ? transformer.nameplateRatingAssigned
                    : transformer.nameplateRating}
                </td>
                <td>{transformer.designVoltage}</td>
                {!AssessmentResultsLimitedEnabled && (
                  <>
                    <td>{transformer.hvFaultLevel}</td>
                    <td>{transformer.xrRatio}</td>
                  </>
                )}
                <td>{transformer.annotation}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <h5 style={{ paddingLeft: ".3rem" }}>Cables</h5>
        <Table
          style={{ marginBottom: "3rem" }}
          className={`table-sm table-custom table-custom-${REACT_APP_THEME}`}
        >
          <thead>
            <tr className="mb-4">
              <th>Branch (near-far)</th>
              <th>Cable Group</th>
              <th>No Phases</th>
              <th>Auto</th>
              <th>Cable Type</th>
              <th>Length (m)</th>
              <th>Ducted</th>
              <th>Distributed Connections</th>
            </tr>
          </thead>
          <tbody>
            {networkCables[reportId]?.map((cable) => (
              <tr key={cable.id}>
                <td>
                  {cable.nearNodeNumber} - {cable.farNodeNumber}
                </td>
                <td>{cable.cableGroup}</td>
                <td>{cable.numberOfPhases}</td>
                <td>{cable.autoSelect.toString()}</td>
                {cable.cableType === "auto" ? (
                  <td>{cable.cableTypeAssigned}</td>
                ) : (
                  <td>{cable.cableType}</td>
                )}
                <td>
                  {parseFloat(cable.overrideLength)
                    ? cable.overrideLength
                    : parseFloat(cable.length).toFixed(2)}
                </td>
                <td>{cable.ducting.toString()}</td>
                <td>{cable.groupedConnectionPoints.length > 0 ? "true" : "false"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        {networkGroupedConnections[reportId] && (
          <ReportTable
            header="Nodal Connections"
            obj={networkGroupedConnections[reportId]}
            theme={REACT_APP_THEME}
          />
        )}
        {networkCables[reportId] && (
          <ReportTable
            header="Distributed Connections"
            obj={networkCables[reportId]}
            theme={REACT_APP_THEME}
          />
        )}
        {networkTransformers[reportId] && (
          <ReportTable
            header="Transformer Connections"
            obj={networkTransformers[reportId]}
            theme={REACT_APP_THEME}
          />
        )}
        {networkMotors[reportId] && networkMotors[reportId].length > 0 && (
          <>
            <h5 style={{ paddingLeft: ".3rem" }}>Motors</h5>
            <Table
              style={{ marginBottom: "3rem" }}
              className={`table-sm table-custom table-custom-${REACT_APP_THEME}`}
            >
              <thead>
                <tr className="mb-4">
                  <th>Node No</th>
                  <th>No Phases</th>
                  <th>Rating</th>
                  <th>Rating Unit</th>
                  <th>Starter</th>
                  <th>No Starts</th>
                  <th>Starts Unit</th>
                  <th>Starting PF</th>
                  <th>Start Current Override</th>
                  <th>Efficiency</th>
                </tr>
              </thead>
              <tbody>
                {networkMotors[reportId].map((motor) => (
                  <tr key={motor.id}>
                    <td>{motor.nodeNumber}</td>
                    <td>{motor.numberOfPhases}</td>
                    <td>{motor.rating}</td>
                    <td>{motor.ratingUnit}</td>
                    <td>{motor.starter}</td>
                    <td>{motor.numberOfStarts}</td>
                    <td>{motor.numberOfStartsUnit}</td>
                    <td>{motor.startingPowerFactor}</td>
                    <td>{motor.startingCurrentOverride}</td>
                    <td>{motor.efficiency}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
        {networkWelders[reportId] && networkWelders[reportId].length > 0 && (
          <>
            <h5 style={{ paddingLeft: ".3rem" }}>Welders</h5>
            <Table
              style={{ marginBottom: "3rem" }}
              className={`table-sm table-custom table-custom-${REACT_APP_THEME}`}
            >
              <thead>
                <tr className="mb-4">
                  <th>Node No</th>
                  <th>No Phases</th>
                  <th>
                    {networkWelders[reportId].map((welder) =>
                      welder.ratingUnit === "A" ? "Maximum Welding Current" : "Rating",
                    )}
                  </th>
                  {networkWelders[reportId].some((p) => p.ratingUnit === "A") && <th>Voc</th>}
                  <th>No Bursts</th>
                  <th>Bursts Unit</th>
                  <th>POWC</th>
                  <th>Starting PF</th>
                  <th>Efficiency</th>
                </tr>
              </thead>
              <tbody>
                {networkWelders[reportId].map((welder) => (
                  <tr key={welder.id}>
                    <td>{welder.nodeNumber}</td>
                    <td>{welder.numberOfPhases}</td>
                    <td>{welder.rating}</td>
                    {networkWelders[reportId].some((p) => p.ratingUnit === "A") && (
                      <td>{welder.ratingUnit === "A" ? welder.voc : ""}</td>
                    )}
                    <td>{welder.numberOfBursts}</td>
                    <td>{welder.numberOfBurstsUnit}</td>
                    <td>{welder.pointOfWaveControl.toString()}</td>
                    <td>{welder.powerFactor}</td>
                    <td>{welder.efficiency}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
      </Container>
      {clientSettings.features.CostingEnabled ? (
        <Container className="report pagebreak">
          <h2 className="mb-4" style={{ paddingLeft: ".3rem" }}>
            Bill Of Materials
          </h2>
          <Table
            style={{ marginBottom: "3rem" }}
            className={`table-sm table-custom table-custom-${REACT_APP_THEME}`}
          >
            <thead>
              <tr className="mb-4">
                <th>Status</th>
                <th>Work Category 1</th>
                <th>SWE</th>
                <th>SWE Description</th>
                <th className=" text-md-right">Quantity</th>
              </tr>
            </thead>
            <tbody>
              {billOfMaterials && billOfMaterials?.items ? (
                billOfMaterials.items.map((bom, index) => (
                  <tr key={bom.swe}>
                    <td>{bom.status}</td>
                    <td>{bom.parentCategory}</td>
                    <td>{bom.swe}</td>
                    <td width="60%">{bom.sweDescription}</td>
                    <td className="text-md-right">{bom.quantity}</td>
                  </tr>
                ))
              ) : billOfMaterials ? (
                <tr key={"bom-NA"}>
                  <td colSpan={7}>N/A</td>
                </tr>
              ) : (
                <tr key={"bom-fail"}>
                  <td colSpan={7}>Error - Unable to produce Bill of Materials</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Container>
      ) : (
        <Container className="report pagebreak">
          <h2 className="mb-4" style={{ paddingLeft: ".3rem" }}>
            Bill Of Materials
          </h2>
          <h5 style={{ paddingLeft: ".3rem" }}>Transformer</h5>
          <Table
            style={{ marginBottom: "3rem" }}
            className={`table-sm table-custom table-custom-${REACT_APP_THEME}`}
          >
            <thead>
              <tr className="mb-4">
                <th>Type</th>
                <th>Phases</th>
                <th>Rating (kVA)</th>
              </tr>
            </thead>
            <tbody>
              {networkTransformers[reportId].map((transformer) => (
                <tr key={transformer.id}>
                  <td>{transformer.mounting === "pmt" ? "Pole Mounted" : "Ground Mounted"}</td>
                  <td>{transformer.numberOfPhases}</td>
                  <td>
                    {transformer.nameplateRating === "auto"
                      ? transformer.nameplateRatingAssigned
                      : transformer.nameplateRating}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <h5 style={{ paddingLeft: ".3rem" }}>Cables</h5>
          <Table
            style={{ marginBottom: "3rem" }}
            className={`table-sm table-custom table-custom-${REACT_APP_THEME}`}
          >
            <thead>
              <tr className="mb-4">
                <th>Type</th>
                <th>Phases</th>
                <th>Size</th>
                <th>Length (m)</th>
              </tr>
            </thead>
            <tbody>
              {getBomCables(reportId).map((cable) => (
                <tr key={cable.id}>
                  <td>{cable.cableGroup}</td>
                  <td>{cable.numberOfPhases}</td>
                  {cable.cableType === "auto" ? (
                    <td>{cable.cableTypeAssigned}</td>
                  ) : (
                    <td>{cable.cableType}</td>
                  )}
                  <td>
                    {parseFloat(cable.overrideLength)
                      ? cable.overrideLength
                      : parseFloat(cable.length).toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>
      )}
      <Container className="report pagebreak">
        <h2 className="mb-4" style={{ paddingLeft: ".3rem" }}>
          Results
        </h2>
        {Object.values(getFeeders(reportId)).map((item) => (
          <div key={item[0].feederNumber}>
            <h5 style={{ paddingLeft: ".3rem" }}>
              {`Feeder ${item[0].feederNumber}`}
              <small>
                {" "}
                - Chosen Fuse Size:{" "}
                <span className={item[0].cable.hasFuseMaxCurrentWarning ? "alert-danger" : ""}>
                  {item[0].cable.feederFuseSize}A
                </span>
              </small>
            </h5>
            <Table className={`table-sm table-custom table-custom-${REACT_APP_THEME}`}>
              <thead className="superthead">
                <tr className="mb-4">
                  <th colSpan="2" className="th-separator">
                    Nodes
                  </th>
                  <th colSpan={AssessmentResultsLimitedEnabled ? 2 : 4} className="th-separator">
                    Branch
                  </th>
                  {!AssessmentResultsLimitedEnabled && (
                    <th colSpan="6" className="th-separator">
                      Far Node
                    </th>
                  )}
                </tr>
              </thead>
              <thead>
                <tr className="mb-4">
                  <th className="th-separator">Near</th>
                  <th>Far</th>
                  <th className="th-separator">Max A</th>
                  <th>% utilisation</th>
                  {!AssessmentResultsLimitedEnabled && (
                    <>
                      <th>Period</th>
                      <th>Season</th>
                      <th className="th-separator">Volt Drop</th>
                      <th>Period</th>
                      <th>Season</th>
                      <th>Volt Rise</th>
                      <th>Period</th>
                      <th>Season</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {item.map((x, i) => (
                  <tr key={i}>
                    <td>{x.nearNode && x.nearNode.nodeNumber}</td>
                    <td>{x.farNode && x.farNode.nodeNumber}</td>
                    <td className={showAlert(x, "maximumUtilisationPercent", "cableResults")}>
                      {x.cable[`AllmaximumCurrentA`]}
                    </td>
                    <td className={showAlert(x, "maximumUtilisationPercent", "cableResults")}>
                      {x.cable[`AllmaximumUtilisationPercent`]}
                    </td>
                    {!AssessmentResultsLimitedEnabled && (
                      <>
                        <td>{x.cable[`AllmaximumCurrentAPeriod`]}</td>
                        <td>{getMaximumSeason(x.cable, "maximumCurrentA")}</td>
                        <td className={showAlert(x, "maxVoltDropPercent", "farNodeResults")}>
                          {x.farNode && x.farNode[`AllmaxVoltDropPercent`]}
                        </td>
                        <td>{x.farNode && x.farNode[`AllmaxVoltDropPercentPeriod`]}</td>
                        <td>{getMaximumSeason(x.farNode, "maxVoltDropPercent")}</td>
                        <td className={showAlert(x, "maxVoltRiseTotal", "farNodeResults")}>
                          {x.farNode && x.farNode[`AllmaxVoltRiseTotal`]}
                        </td>
                      </>
                    )}
                    {!AssessmentResultsLimitedEnabled && (
                      <>
                        <td>{x.farNode && x.farNode[`AllmaxVoltRisePercentPeriod`]}</td>
                        <td>{getMaximumSeason(x.farNode, "maxVoltRiseTotal")}</td>
                        <td className={showAlert(x, "maxVoltRiseGeneration", "farNodeResults")}>
                          {x.farNode && x.farNode[`AllmaxVoltRiseGeneration`]}
                        </td>
                        <td>{x.farNode && x.farNode[`AllmaxVoltRisePercentPeriod`]}</td>
                        <td>{getMaximumSeason(x.farNode, "maxVoltRiseGeneration")}</td>
                        <td className={showAlert(x, "maxVoltRiseUnbalance", "farNodeResults")}>
                          {x.farNode && x.farNode[`AllmaxVoltRiseUnbalance`]}
                        </td>
                        <td>{x.farNode && x.farNode[`AllmaxVoltRisePercentPeriod`]}</td>
                        <td>{getMaximumSeason(x.farNode, "maxVoltRiseUnbalance")}</td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
            <Table
              style={{ marginBottom: "3rem" }}
              className={`table-sm table-custom table-custom-${REACT_APP_THEME}`}
            >
              <thead className="superthead">
                <tr className="mb-4">
                  <th colSpan="2" className="th-separator">
                    Nodes
                  </th>
                  <th colSpan={AssessmentResultsLimitedEnabled ? 1 : 2} className="th-separator">
                    Source Impedance (mΩ)
                  </th>
                  <th colSpan={AssessmentResultsLimitedEnabled ? 1 : 3} className="th-separator">
                    Fault Level (kA)
                  </th>
                  {AssessmentResultsLimitedEnabled && (
                    <th colSpan="2" className="th-separator">
                      Far Node
                    </th>
                  )}
                  {!AssessmentResultsLimitedEnabled && (
                    <th colSpan="5" className="th-separator">
                      Flicker
                    </th>
                  )}
                </tr>
              </thead>
              <thead>
                <tr className="mb-4">
                  <th className="th-separator">Near</th>
                  <th>Far</th>
                  <th className="th-separator">Loop</th>
                  {!AssessmentResultsLimitedEnabled && <th>Phase</th>}
                  <th className="th-separator">Phase-Earth</th>
                  {AssessmentResultsLimitedEnabled && (
                    <>
                      <th className="th-separator">Volt Drop</th>
                      <th>Volt Rise</th>
                    </>
                  )}
                  {!AssessmentResultsLimitedEnabled && (
                    <>
                      <th>Phase-Phase</th>
                      <th>3-Phase</th>
                      <th className="th-separator">Steady State Voltage %</th>
                      <th>Pass/Refer</th>
                      <th>Total Voltage During Starting %</th>
                      <th>Pass/Refer</th>
                      <th>P28 Limit</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {item.map((y, i) => (
                  <tr key={i}>
                    <td>{y.nearNode && y.nearNode.nodeNumber}</td>
                    <td>{y.farNode && y.farNode.nodeNumber}</td>
                    <td className={showAlert(y, "impedanceSourceLoopOperating", "farNodeResults")}>
                      {y.farNode && y.farNode.impedanceSourceLoopOperating}
                    </td>
                    {!AssessmentResultsLimitedEnabled && (
                      <td>{y.farNode && y.farNode.impedanceSourcePhaseOperating}</td>
                    )}
                    <td
                      className={`${showAlerts(y, [
                        { constraint: "singlePhaseToEarthFaultCurrent", tab: "farNodeResults" },
                        { flag: "hasFuseWarning", tab: "farNode" },
                      ])}`}
                    >
                      {y.farNode && y.farNode.singlePhaseToEarthFaultCurrent}
                    </td>
                    {AssessmentResultsLimitedEnabled && (
                      <>
                        <td className={showAlert(y, "maxVoltDropPercent", "farNodeResults")}>
                          {y.farNode && y.farNode[`AllmaxVoltDropPercent`]}
                        </td>
                        <td className={showAlert(y, "maxVoltRiseTotal", "farNodeResults")}>
                          {y.farNode && y.farNode[`AllmaxVoltRiseTotal`]}
                        </td>
                      </>
                    )}
                    {!AssessmentResultsLimitedEnabled && (
                      <>
                        <td className={showAlert(y, "phaseToPhaseFaultCurrent", "farNodeResults")}>
                          {y.farNode && y.farNode.phaseToPhaseFaultCurrent}
                        </td>
                        <td className={showAlert(y, "threePhaseFaultCurrent", "farNodeResults")}>
                          {y.farNode && y.farNode.threePhaseFaultCurrent}
                        </td>
                        <td className={showAlert(y, "flickerDeltaVoltage", "farNodeResults")}>
                          {y.farNode && y.farNode.flickerDeltaVoltage}
                        </td>
                        <td>{getSteadyVoltagePassResult(y.farNode)}</td>
                        <td
                          className={showAlert(y, "flickerStartingDeltaVoltage", "farNodeResults")}
                        >
                          {y.farNode && y.farNode.flickerStartingDeltaVoltage}
                        </td>
                        <td>{getStartingVoltagePassResult(y.farNode)}</td>
                        <td>{y.farNode && y.farNode.flickerP28Limit}</td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ))}
        {Object.values(getFlicker(reportId)).length > 0 && (
          <>
            <h5 style={{ paddingLeft: ".3rem" }}>Flicker</h5>
            <Table
              style={{ marginBottom: "3rem" }}
              className={`table-sm table-custom table-custom-${REACT_APP_THEME}`}
            >
              <thead>
                <tr className="mb-4">
                  <th>Node Number</th>
                  <th>Steady State Voltage %</th>
                  <th>Pass/Refer</th>
                  <th>Total Voltage During Starting %</th>
                  <th>Pass/Refer</th>
                  <th>P28 Limit</th>
                </tr>
              </thead>
              <tbody>
                {Object.values(getFlicker(reportId)).map((item) => (
                  <tr key={item.asset.id}>
                    <td>{item.asset.nodeNumber}</td>
                    <td className={showAlert(item, "flickerDeltaVoltage")}>
                      {item.asset.flickerDeltaVoltage}
                    </td>
                    <td>{getSteadyVoltagePassResult(item.asset)}</td>
                    <td className={showAlert(item, "flickerStartingDeltaVoltage")}>
                      {item.asset.flickerStartingDeltaVoltage}
                    </td>
                    <td>{getStartingVoltagePassResult(item.asset)}</td>
                    <td>{item.asset.flickerP28Limit}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
        <h5 style={{ paddingLeft: ".3rem" }}>Transformer</h5>
        <Table
          style={{ marginBottom: "3rem" }}
          className={`table-sm table-custom table-custom-${REACT_APP_THEME}`}
        >
          <thead>
            <tr className="mb-4">
              <th>Tx Number</th>
              <th>Rating (kVA)</th>
              <th>Max Load kVA</th>
              <th>Max Utilisation</th>
              <th>Period of Max Load</th>
              <th>Max Volt Drop</th>
              <th>Max Volt Rise Total</th>
              <th>Max Volt Rise Generation</th>
              <th>Max Volt Rise Unbalance</th>
            </tr>
          </thead>
          {Object.values(getTransformers(reportId)).map((item) => (
            <tbody key={item.asset.id}>
              <tr>
                <td>{item.asset.nodeNumber}</td>
                <td className={showAlert(item, "nameplateRatingAssigned")}>
                  {item.asset[`nameplateRatingAssigned`]}
                </td>
                <td className={showAlert(item, "maximumDemandKVA")}>
                  {item.asset[`AllmaximumDemandKVA`]}
                </td>
                <td className={showAlert(item, "maximumUtilisationPercent")}>
                  {item.asset[`AllmaximumUtilisationPercent`]}
                </td>
                <td className={showAlert(item, "maximumDemandKVAPeriod")}>
                  {item.asset[`AllmaximumDemandKVAPeriod`]}
                </td>
                <td className={showAlert(item, "maxVoltDropPercent")}>
                  {item.asset[`AllmaxVoltDropPercent`]}
                </td>
                <td className={showAlert(item, "maxVoltRiseTotal")}>
                  {item.asset[`AllmaxVoltRiseTotal`]}
                </td>
                <td className={showAlert(item, "maxVoltRiseGeneration")}>
                  {item.asset[`AllmaxVoltRiseGeneration`]}
                </td>
                <td className={showAlert(item, "maxVoltRiseUnbalance")}>
                  {item.asset[`AllmaxVoltRiseUnbalance`]}
                </td>
              </tr>
            </tbody>
          ))}
        </Table>
      </Container>
      <Container className="report pagebreak">
        <h5 style={{ paddingLeft: ".3rem" }}>System Parameters</h5>
        <Table
          style={{ marginBottom: "3rem" }}
          className={`table-sm table-custom table-custom-${REACT_APP_THEME} w-auto`}
        >
          <tbody>
            <tr>
              <th>System Voltage</th>
              <td style={{ paddingTop: ".3rem" }}>{reference.constraints.systemVoltage}</td>
              <td style={{ paddingTop: ".3rem" }}>V</td>
            </tr>
            <tr>
              <th>Fault Voltage</th>
              <td>250</td>
              <td>V</td>
            </tr>
            <tr>
              <th>System Phase Angle</th>
              <td>{getTransformer(reportId)?.phaseAngle}</td>
              <td>degrees</td>
            </tr>
            <tr>
              <th>Number of Available Phases</th>
              <td>{getTransformer(reportId)?.phasesAvailable}</td>
              <td></td>
            </tr>
            <tr>
              <td colSpan="3" className="pt-4 border-bottom-0">
                Constraint Thresholds
              </td>
            </tr>
            <tr>
              <th>Maximum Mains Volt Drop</th>
              <td>{overrideConstraints.maxVoltDropPercent}</td>
              <td>%</td>
            </tr>
            <tr>
              <th>Maximum Service Volt Drop</th>
              <td>{overrideConstraints.maxVoltDropServicePercent}</td>
              <td>%</td>
            </tr>
            <tr>
              <th>Maximum Volt Rise Total</th>
              <td>{overrideConstraints.maxVoltRisePercent}</td>
              <td>%</td>
            </tr>
            <tr>
              <th>Maximum Volt Rise Generation</th>
              <td>{getConstraint("Voltage/Current", "Max Volt Rise Generation (%)")}</td>
              <td>%</td>
            </tr>
            <tr>
              <th>Maximum Volt Rise Unbalance</th>
              <td>{getConstraint("Voltage/Current", "Max Volt Rise Unbalance (%)")}</td>
              <td>%</td>
            </tr>
            <tr>
              <th>Maximum Operating Resistance</th>
              <td>{getConstraint("Impedance", "Resistance (mΩ)", "Loop (operating)")}</td>
              <td>mΩ</td>
            </tr>
          </tbody>
        </Table>
        <h5 style={{ paddingLeft: ".3rem" }}>Auto Cable Groups</h5>
        <Table
          style={{ marginBottom: "3rem" }}
          className={`table-sm table-custom table-custom-${REACT_APP_THEME} w-auto`}
        >
          <thead>
            <tr className="mb-4">
              <th>Name</th>
              <th>Cable Types</th>
            </tr>
          </thead>
          <tbody>
            {applyCableAutoSelectOverride(
              reference.cables.autoSelectGroups,
              localOverrideConfig,
            ).map((group, i) => (
              <tr key={i}>
                <td>{group.name}</td>
                <td>{group.cables.join(", ")}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <h5 style={{ paddingLeft: ".3rem" }}>Auto Transformer Groups</h5>
        <Table
          style={{ marginBottom: "3rem" }}
          className={`table-sm table-custom table-custom-${REACT_APP_THEME} w-auto`}
        >
          <thead>
            <tr className="mb-4">
              <th>Mounting</th>
              <th>Phase</th>
              <th>Rating</th>
            </tr>
          </thead>
          <tbody>
            {applyTransformerAutoSelectOverride(
              reference.transformers.autoSelectGroups,
              localOverrideConfig,
            ).map((group, i) => (
              <tr key={i}>
                <td>{group.groundMounted ? "Ground" : "Pole"}</td>
                <td>{group.phaseAngle}</td>
                <td>{group.ratingkVAs.join(", ")}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </>
  );
};

export default ResultReport;
