import * as alert from "../constants/alert";
import { Transformer } from "../model/viewModel/transformer";
import { TransformerSearchValueItem } from "../model/internal/transformerSearchValue";

const CODE_W105 = "W105";
const SYSTEM_FAULT_VOLTAGE = 260;

export const checkDesignVoltage = (t: Transformer) => {
  let warning = {};

  if (t.designVoltage > SYSTEM_FAULT_VOLTAGE) {
    warning = {
      code: CODE_W105,
      level: alert.LEVEL_WARNING,
      link: t.id,
      description: `Design Voltage is greater than System Fault Voltage (${SYSTEM_FAULT_VOLTAGE}V).`,
    };
  }

  return warning;
};

export const getSubstationId = (searchValue: TransformerSearchValueItem[] | null): string => {
  if (!Array.isArray(searchValue) || !searchValue.length) {
    return "";
  }
  return searchValue[0]?.key ?? "";
};

export const getDefaultAnnotation = (searchValue: TransformerSearchValueItem[] | null): string => {
  if (!Array.isArray(searchValue) || !searchValue.length) {
    return "";
  }
  if (!searchValue[0]?.key) return "";
  if (!searchValue[0]?.value) return "";
  return `${searchValue[0].value} (${searchValue[0].key})`;
};

export const getTransformerSearchValue = (
  substationId: string,
  name: string,
): TransformerSearchValueItem[] => {
  return [
    {
      key: substationId,
      value: name,
    },
  ];
};
