import { useContext, useState } from "react";
import {
  Input,
  InputGroup,
  InputGroupAddon,
  Button,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from "reactstrap";
import { useMsal } from "@azure/msal-react";
import { useDebouncedCallback } from "use-debounce";
import { getLocation, getAutoCompleteLocation } from "../services/apiService";
import { ToolContext } from "../context/ToolContext";
import useBearerToken from "../utils/useBearerToken";
import { PromptInput } from "./PromptInput";
import "./MapSearch.css";

const MapSearch = ({ autoComplete }) => {
  const [searchValue, setSearchValue] = useState("");
  const { toolState, setToolState } = useContext(ToolContext);
  const { instance, accounts } = useMsal();
  const [autoCompleteLocation, setAutoCompleteLocation] = useState([]);
  const getToken = useBearerToken();

  const setToolStateProp = (prop, value) => {
    const _toolState = toolState;
    _toolState[prop] = {
      type: "warning",
      className: "warning",
      messages: value,
    };
    setToolState(_toolState);
  };

  const postcodeToSetView = async () => {
    const token = await getToken(instance, accounts);
    const location = await getLocation(token, searchValue);

    if (location?.data?.messages?.length > 0) {
      setToolStateProp("alert", location?.data?.messages);
      return;
    }

    if (!location.data || location.data.totalResults === 0) {
      return;
    }

    const x = location.data.locationValue_X;
    const y = location.data.locationValue_Y;
    const latLng = { lat: x, lng: y };

    setToolState({
      navigateToLatLng: latLng,
    });
    document.getElementById("location-search").value = searchValue;
  };

  const handleLocationChange = useDebouncedCallback(async (value) => {
    if (autoComplete) {
      if (value.length < 3) {
        setAutoCompleteLocation([]);
        return;
      }
      const token = await getToken(instance, accounts);
      const location = await getAutoCompleteLocation(token, value);
      setAutoCompleteLocation(location.data.results);
    }
    setSearchValue(value);
  }, 300);

  const autoCompleteLocationToSetView = (location) => {
    const latLng = { lat: location.lat, lng: location.lng };
    setToolState({
      navigateToLatLng: latLng,
    });
    setAutoCompleteLocation([]);
    document.getElementById("location-search").value = location.place;
  };

  return (
    <div className="m-3 position-relative">
      <InputGroup>
        <PromptInput
          autoComplete={autoComplete ? "off" : "on"}
          type="text"
          id="location-search"
          placeholder="Search for a Location"
          bsSize="sm"
          onChange={(e) => handleLocationChange(e.target.value)}
        />
        {!autoComplete && (
          <InputGroupAddon addonType="append">
            <Button
              id="btnSearchSub"
              size="sm"
              color="primary"
              disabled={!searchValue}
              onClick={() => postcodeToSetView()}
            >
              Search
            </Button>
          </InputGroupAddon>
        )}
      </InputGroup>
      {autoComplete && autoCompleteLocation && autoCompleteLocation.length > 0 && (
        <ListGroup className="autocomplete w-100 mt-2">
          <>
            {autoCompleteLocation.map((location) => (
              <ListGroupItem
                key={location.placeId}
                action
                tag="button"
                className="text-light bg-dark p-2"
                onClick={() => autoCompleteLocationToSetView(location)}
              >
                <ListGroupItemHeading className="font-bold m-0" tag="h6">
                  <strong>{location.place}</strong>
                </ListGroupItemHeading>
                <ListGroupItemText className="m-0">{location.address}</ListGroupItemText>
              </ListGroupItem>
            ))}
          </>
        </ListGroup>
      )}
    </div>
  );
};

export default MapSearch;
