import { useState, useContext } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import { useMsal } from "@azure/msal-react";

import { getSpecificReference, loadNetworkJson } from "../services/apiService";
import { setConstraints } from "../services/constraintsService";
import { FormContext } from "../context/FormContext";
import { createNetworkFromAssessmentResponse } from "../model/viewModel/networkFactory";
import { ToolContext } from "../context/ToolContext";
import * as alert from "../constants/alert";
import WarningModal from "./WarningModal";
import MultipleTransfomersModal from "./MultipleTransfomersModal";
import {
  resetNetworkToolState,
  resetNetworkElements,
  centreNetworkOnMap,
} from "../utils/networkFunctions";
import { getSubstationId, getDefaultAnnotation } from "../utils/transformerFunctions";
import { useSelector, useDispatch } from "react-redux";
import {
  clearReduxNetwork,
  checkReduxNetworkPopulated,
  addWelders,
  addMotors,
  addPointOfConnections,
  addTransformers,
  addGroupedConnections,
  addNodes,
  addConnectionPoints,
  addCables,
  replaceWelders,
  replaceMotors,
  replacePointOfConnections,
  replaceTransformers,
  replaceGroupedConnections,
  replaceNodes,
  replaceConnectionPoints,
  replaceCables,
  setAsSaved,
  setHideMap,
  replaceWeldersBySubstationId,
  replaceMotorsBySubstationId,
  replacePointOfConnectionsBySubstationId,
  replaceTransformersBySubstationId,
  replaceGroupedConnectionsBySubstationId,
  replaceNodesBySubstationId,
  replaceConnectionPointsBySubstationId,
  replaceCablesBySubstationId,
  setNetworkCreated,
  setSubstationImportedChanged,
  findAll,
} from "../app/networkSlice";
import { store } from "../app/store";
import { clearHistoryAction, endBatchAction, startBatchAction } from "../app/undoable";
import { codes } from "../constants/highlightableErrorCodes";
import useBearerToken from "../utils/useBearerToken";

const FeederFilter = ({ changeNetwork }) => {
  const [, setFaults] = useState([]);
  const { formState, dispatch } = useContext(FormContext);
  const { reference, clientSettings } = formState;
  const { toolState, setToolState } = useContext(ToolContext);
  const { filteredFeeders, feederList } = toolState;
  const { searchValue } = toolState;
  const { mainsOnly } = toolState;
  const { electricOfficeOnly, leafletMap, useAntData } = toolState;
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showAcceptToConnectMessage, setShowAcceptToConnectMessage] = useState([]);
  const [showMultipleTransfomersModal, setShowMultipleTransfomersModal] = useState(false);
  const [showRemoveNetworkModal, setShowRemoveNetworkModal] = useState(false);
  const [showNetworkCreatedOrChangedWarningModal, setShowNetworkCreatedOrChangedWarningModal] =
    useState(false);
  const [showConflictingConfigWarningModal, setShowConflictingConfigWarningModal] = useState(false);
  const [transformerCount, setTransformerCount] = useState(0);
  const dispatchRedux = useDispatch();

  const isReduxNetworkPopulated = useSelector((state) => checkReduxNetworkPopulated(state));
  const networkChanged = useSelector((state) => state.network.present.networkChanged);
  const substationImportedChanged = useSelector(
    (state) => state.network.present.substationImportedChanged,
  );

  const transformers = useSelector((state) => state.network.present.transformers);
  const hideMap = useSelector((state) => state.network.present.hideMap);
  const groupedConnections = useSelector((state) => state.network.present.groupedConnections);
  const allAssets = useSelector((state) => findAll(state));

  const { instance, accounts } = useMsal();
  const getToken = useBearerToken();

  const mainsOnlyToggle = () => {
    setToolState({
      mainsOnly: !toolState.mainsOnly,
    });
  };
  const electricOfficeOnlyToggle = () => {
    setToolState({
      electricOfficeOnly: !toolState.electricOfficeOnly,
    });
  };

  const showLoadNetworkModals = () => {
    const currentSearchValue = getCurrentSearchValue();
    const toggleFeederRefreshEnabled = clientSettings.features.ToggleFeederRefreshEnabled;

    if (!toggleFeederRefreshEnabled) {
      if (transformers.some((p) => p.substationId === currentSearchValue)) {
        setToolState({
          alert: {
            type: alert.ERROR,
            className: alert.DANGER,
            messages: [{ description: "Substation has already been loaded" }],
          },
          isLoading: false,
        });
        return;
      }
    }
    if (
      filteredFeeders[parseSearchValue()] &&
      toggleFeederRefreshEnabled &&
      feederList.length === filteredFeeders[parseSearchValue()].length
    ) {
      setShowRemoveNetworkModal(true);
      return;
    }
    if (
      substationImportedChanged &&
      allAssets.some((p) => p.asset.substationId === currentSearchValue)
    ) {
      setShowNetworkCreatedOrChangedWarningModal(true);
      return;
    }
    loadNetwork(true);
  };

  const loadNetwork = async (canProcess) => {
    setShowNetworkCreatedOrChangedWarningModal(false);
    dispatchRedux(setNetworkCreated(false));

    if (!canProcess) {
      return;
    }

    const currentSearchValue = getCurrentSearchValue();

    const _transformerCount = transformers.length;
    const multipleTransformersEnabled = clientSettings.features.MultipleTransformersEnabled;

    if (_transformerCount && multipleTransformersEnabled) {
      if (transformers.some((p) => p.substationId === currentSearchValue)) {
        searchNetwork("replace");
      } else {
        setShowMultipleTransfomersModal(true);
      }
      setTransformerCount(_transformerCount);
    } else {
      searchNetwork("replaceAll", false);
    }
  };

  const parseSearchValue = () => {
    return getSubstationId(searchValue);
  };

  const getCurrentSearchValue = () => {
    const substationSearch = document.getElementById("substation-search");
    const parsedSearchValue = parseSearchValue();
    const value = parsedSearchValue ?? substationSearch.value;
    return Array.isArray(value) ? (value.length > 0 ? value[0].key : "") : value;
  };

  const searchNetwork = async (action, clearFeederList = true) => {
    setShowMultipleTransfomersModal(false);
    setShowRemoveNetworkModal(false);

    if (!action) return;
    const currentSearchValue = getCurrentSearchValue();

    if (!currentSearchValue) return;
    const token = await getToken(instance, accounts);

    const canManageConfigs =
      clientSettings.features.SelectOnlyClientConfigEnabled ||
      clientSettings.features.SelectAnyClientConfigEnabled ||
      clientSettings.features.SelectHistoricClientConfigEnabled;

    if (reference.version.client !== clientSettings.general.defaultConfigurationClient) {
      throw new Error("The current client does not match the network being loaded");
    }

    let currentReference = reference;
    if (canManageConfigs && !reference.version.isLatest) {
      if (isReduxNetworkPopulated && action === "add") {
        setShowConflictingConfigWarningModal(true);
        return;
      } else {
        currentReference = await getSpecificReference(
          token,
          reference.version.client,
          reference.version.latestVersionNumber,
        );
        dispatch({
          form: "reference",
          obj: currentReference,
          type: "REPLACE_STATE",
        });
      }
    }

    setToolState({
      isLoading: "Loading network",
      activeTool: "",
      clickedAsset: null,
    });

    const response = await loadNetworkJson(
      token,
      currentSearchValue,
      filteredFeeders[parseSearchValue()] || [],
      mainsOnly,
      electricOfficeOnly,
      useAntData,
    );

    const messages = response?.data?.network?.messages;
    const allWarnings = messages.every((s) => s.level === "Warning");
    const anyMessages = messages.length > 0;
    const isHighlightableError = (codes) => {
      return messages.some((s) => codes.includes(s.code)) ? { messages: messages } : {};
    };

    if (allWarnings && anyMessages) {
      setToolState({
        alert: {
          type: alert.WARNING,
          className: alert.WARNING,
          messages: response.data.network.messages,
        },
        isLoading: false,
        errors: isHighlightableError(codes),
      });
    }
    if (!allWarnings && anyMessages) {
      setToolState({
        alert: {
          type: alert.ERROR,
          className: alert.DANGER,
          messages: response.data.network.messages,
        },
        isLoading: false,
      });
      return;
    }
    const network = createNetworkFromAssessmentResponse(
      currentReference,
      response,
      clientSettings.dynamicProperties,
      currentSearchValue,
      action === "add" ? groupedConnections : undefined,
      dispatchRedux,
      true,
    );

    const transformer = network.transformers.find((t) => t.substationId === currentSearchValue);
    if (transformer && !transformer.annotation) {
      const currentAnnotation = transformers.find(
        (t) => t.substationId === currentSearchValue,
      )?.annotation;
      if (currentAnnotation) {
        transformer.annotation = currentAnnotation;
      } else {
        const txDefaultAnnotation = getDefaultAnnotation(searchValue);
        if (txDefaultAnnotation) {
          transformer.annotation = txDefaultAnnotation;
        }
      }
    }

    changeNetwork();
    const allNewGeometries = addOrReplaceNetworkState(network, action, clearFeederList);
    replaceRagNetworksState(network);
    if (action !== "add") {
      dispatchRedux(setSubstationImportedChanged(false));
    }
    setToolState({
      isLoading: false,
      drawBoundary: false,
      ringfenced: [],
      ringfencedFiltered: [],
      ringfencedTypes: [],
      disableBoundary: false,
      showResults: false,
      showNearbyAssets: { disabled: false, substations: false },
      feederList: action !== "remove" ? feederList : null,
      searchValue: action !== "remove" ? searchValue : null,
    });
    if (action !== "remove") {
      centreNetworkOnMap(network, allNewGeometries, leafletMap);
    }
    if (response?.data?.acceptToConnect?.connections?.length > 0) {
      setShowAcceptToConnectMessage(
        response.data.acceptToConnect.connections.map((p) => p.enquiryReference),
      );
    }
  };

  const onCheckboxBtnClick = (selected) => {
    const parsedSearchValue = parseSearchValue();
    const feeders = [...(filteredFeeders[parsedSearchValue] || [])];
    const index = feeders.indexOf(selected);

    if (index < 0) {
      feeders.push(selected);
    } else {
      feeders.splice(index, 1);
    }

    setToolState({
      filteredFeeders: { ...filteredFeeders, [parsedSearchValue]: feeders },
    });
  };

  const addOrReplaceNetworkState = (network, action, clearFeederList) => {
    const _formState = { ...formState };
    const constraints = setConstraints(clientSettings, _formState, network);
    const network2 = constraints.network;

    store.dispatch(clearHistoryAction());
    store.dispatch(startBatchAction());
    try {
      if (action === "add") {
        dispatchRedux(addWelders(network2.welders));
        dispatchRedux(addMotors(network2.motors));
        dispatchRedux(addPointOfConnections(network2.pointOfConnections));
        dispatchRedux(addTransformers(network2.transformers));
        dispatchRedux(addGroupedConnections(network2.groupedConnections));
        dispatchRedux(addNodes(network2.nodes));
        dispatchRedux(addConnectionPoints(network2.connectionPoints));
        dispatchRedux(addCables(network2.cables));
        if (!hideMap) {
          dispatchRedux(setHideMap(network2.runtime?.hideMap === true));
        }
      } else if (action === "replace" || action === "remove") {
        const currentSearchValue = getCurrentSearchValue();
        dispatchRedux(
          replaceWeldersBySubstationId({
            substationId: currentSearchValue,
            welders: network2.welders,
          }),
        );
        dispatchRedux(
          replaceMotorsBySubstationId({
            substationId: currentSearchValue,
            motors: network2.motors,
          }),
        );
        dispatchRedux(
          replacePointOfConnectionsBySubstationId({
            substationId: currentSearchValue,
            pointOfConnections: network2.pointOfConnections,
          }),
        );
        dispatchRedux(
          replaceTransformersBySubstationId({
            substationId: currentSearchValue,
            transformers: action === "remove" ? [] : network2.transformers,
          }),
        );
        dispatchRedux(
          replaceGroupedConnectionsBySubstationId({
            substationId: currentSearchValue,
            groupedConnections: network2.groupedConnections,
          }),
        );
        dispatchRedux(
          replaceNodesBySubstationId({
            substationId: currentSearchValue,
            nodes: network2.nodes,
          }),
        );
        dispatchRedux(
          replaceConnectionPointsBySubstationId({
            substationId: currentSearchValue,
            connectionPoints: network2.connectionPoints,
          }),
        );
        dispatchRedux(
          replaceCablesBySubstationId({
            substationId: currentSearchValue,
            cables: network2.cables,
          }),
        );
        dispatchRedux(setHideMap(network2.runtime?.hideMap === true));
      } else if (action === "replaceAll") {
        if (clearFeederList) {
          dispatch({
            form: "importedFiles",
            obj: [],
            type: "REPLACE_STATE4",
          });
          setToolState({
            filteredFeeders: {},
          });
        }
        dispatchRedux(replaceWelders(network2.welders));
        dispatchRedux(replaceMotors(network2.motors));
        dispatchRedux(replacePointOfConnections(network2.pointOfConnections));
        dispatchRedux(replaceTransformers(network2.transformers));
        dispatchRedux(replaceGroupedConnections(network2.groupedConnections));
        dispatchRedux(replaceNodes(network2.nodes));
        dispatchRedux(replaceConnectionPoints(network2.connectionPoints));
        dispatchRedux(replaceCables(network2.cables));
        dispatchRedux(setHideMap(network2.runtime?.hideMap === true));
      }
      dispatchRedux(setAsSaved());
    } finally {
      store.dispatch(endBatchAction());
    }

    const allNewGeometries = (network2.welders ?? [])
      .map((p) => p.geometry)
      .concat((network2.motors ?? []).map((p) => p.geometry))
      .concat((network2.pointOfConnections ?? []).map((p) => p.geometry))
      .concat((network2.transformers ?? []).map((p) => p.geometry))
      .concat((network2.groupedConnections ?? []).map((p) => p.geometry))
      .concat((network2.nodes ?? []).map((p) => p.geometry))
      .concat((network2.connectionPoints ?? []).map((p) => p.geometry))
      .concat((network2.cables ?? []).map((p) => p.geometry));

    _formState.network = {};
    _formState.network.existing = false;
    _formState.network.loaded = true;

    setFaults(constraints.constraints);

    return allNewGeometries;
  };

  const replaceRagNetworksState = () => {
    const _formState = { ...formState };
    _formState.ragNetworks = [];
    dispatch({
      form: "ragNetworks",
      obj: _formState.ragNetworks,
      type: "REPLACE_STATE4",
    });
    if (networkChanged === false) {
      dispatch({
        obj: _formState.ragNetworks,
        type: "SAVE_NETWORK",
      });
    }
  };

  const clearNetwork = (canProcess) => {
    setShowWarningModal(false);

    if (canProcess !== true) {
      return;
    }

    const _toolState = toolState;

    dispatchRedux(clearReduxNetwork());
    resetNetworkToolState(_toolState);
    resetNetworkElements();

    dispatch({
      form: "importedFiles",
      obj: [],
      type: "REPLACE_STATE4",
    });

    setToolState(_toolState);
  };

  const {
    UseAcceptToConnectDataEnabled,
    ImportNetworkMainsOnlyOptionEnabled,
    ImportNetworkEOSearchOptionEnabled,
    CROWNEnabled,
  } = clientSettings.features;

  return (
    <>
      {UseAcceptToConnectDataEnabled && showAcceptToConnectMessage?.length > 0 && (
        <WarningModal
          action={() => setShowAcceptToConnectMessage([])}
          msg={`The following future connections have been accepted on substation ${getCurrentSearchValue()}:`}
          messages={showAcceptToConnectMessage.map((p, i) => {
            return { code: `AcceptToConnect_${i}`, description: p };
          })}
          hideYesButton={true}
          dismissLabel="OK"
        />
      )}
      {feederList && (
        <>
          {showWarningModal && (
            <WarningModal
              item={true}
              action={clearNetwork}
              msg={"Clicking OK will clear the network - are you sure?"}
              yesLabel="OK"
              dismissLabel="Cancel"
            />
          )}
          {showRemoveNetworkModal && (
            <WarningModal
              item={true}
              action={(value) => searchNetwork(value ? "remove" : "")}
              msg={"Clicking OK will remove the network - are you sure?"}
              yesLabel="OK"
              dismissLabel="Cancel"
            />
          )}
          {showNetworkCreatedOrChangedWarningModal && (
            <WarningModal
              item={true}
              action={loadNetwork}
              msg="Clicking OK will revert any changes made to the affected network - press OK to confirm"
              yesLabel="OK"
              dismissLabel="Cancel"
            />
          )}
          {showConflictingConfigWarningModal && (
            <WarningModal
              item={true}
              action={() => setShowConflictingConfigWarningModal(false)}
              msg="The currently loaded network is using an older version of the configuration and must be upgraded before adding a new network"
              dismissLabel="OK"
              className="danger"
              hideYesButton={true}
            />
          )}
          {showMultipleTransfomersModal && (
            <MultipleTransfomersModal action={searchNetwork} transformerCount={transformerCount} />
          )}
          {feederList.length > 0 && (
            <ListGroup flush>
              {feederList.map((feeder) => (
                <ListGroupItem
                  key={feeder.feederNumber}
                  tag="a"
                  className={`d-flex justify-content-between ${
                    filteredFeeders[parseSearchValue()] &&
                    filteredFeeders[parseSearchValue()].includes(feeder.feederNumber)
                      ? "text-black"
                      : ""
                  }`}
                  onClick={() => onCheckboxBtnClick(feeder.feederNumber)}
                >
                  <span>
                    <span>
                      Feeder{" "}
                      {(feeder.properties &&
                        feeder.properties.find((f) => f.name === "feederSequence")?.value) ||
                        feeder.feederNumber}
                    </span>
                    <span
                      className="badge badge-primary ml-3 pl-4 pr-4"
                      style={{ fontSize: "100%" }}
                    >
                      Known Customers (
                      {feeder.properties
                        .filter((p) => p.name !== "feederSequence")
                        .reduce((a, c) => a + parseInt(c.value), 0)}
                      )
                    </span>
                  </span>
                  <span className="list-group-item-icon">
                    <i
                      className={`icon-${
                        filteredFeeders[parseSearchValue()] &&
                        filteredFeeders[parseSearchValue()].includes(feeder.feederNumber)
                          ? "cross"
                          : "check"
                      }-solid`}
                    ></i>
                  </span>
                </ListGroupItem>
              ))}
            </ListGroup>
          )}

          {feederList.length === 0 && (
            <>
              <div
                className={`d-flex justify-content-start text-warning`}
                style={{
                  marginLeft: 16,
                  marginTop: 5,
                  marginBottom: 7,
                }}
              >
                No feeders found, please click Load button to load transformer only
              </div>
            </>
          )}

          <ListGroupItem
            className={`list-group-item-btn-grp d-flex justify-content-start align-items-center pr-3 ${
              feederList.length > 0 ? "" : "border-0"
            }`}
          >
            {feederList.length > 0 && (
              <>
                {ImportNetworkMainsOnlyOptionEnabled && (
                  <div className="show-nearby-assets pr-2">
                    <span onClick={() => mainsOnlyToggle()}>
                      <i
                        className={`icon-${toolState.mainsOnly ? "check" : "cross"}-solid pr-2`}
                      ></i>
                      {`Mains only`}
                    </span>
                  </div>
                )}
                {ImportNetworkEOSearchOptionEnabled && (
                  <div className="show-nearby-assets">
                    <span onClick={() => electricOfficeOnlyToggle()}>
                      <i
                        className={`icon-${
                          toolState.electricOfficeOnly ? "check" : "cross"
                        }-solid pr-2`}
                      ></i>
                      {`Electric Office only`}
                    </span>
                  </div>
                )}
              </>
            )}
            <div className="d-flex justify-content-end flex-fill">
              {CROWNEnabled && (
                <button
                  style={{ width: 62 }}
                  color="primary"
                  className="align-items-center btn btn-primary btn-sm mr-2"
                  onClick={() => setShowWarningModal(true)}
                  disabled={!isReduxNetworkPopulated}
                >
                  Cancel
                </button>
              )}
              <button
                style={{ width: 62 }}
                color="primary"
                className="align-items-center btn btn-primary btn-sm"
                onClick={showLoadNetworkModals}
              >
                Load
              </button>
            </div>
          </ListGroupItem>
        </>
      )}
    </>
  );
};

export default FeederFilter;
