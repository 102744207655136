import { Input, InputGroup } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListGroupItem } from "reactstrap";
import { faArchive, faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { ToolContext } from "../../context/ToolContext";
import { FormContext } from "../../context/FormContext";
import EATooltip from "../../components/EATooltip";
import { PromptInput } from "../PromptInput";
import * as S from "./search-file.style";
import { formatDate, getAreaName, getSpanList, useSearch } from "../../utils";

const SearchFile = ({
  loadedFiles,
  loadNetwork,
  deleteFile,
  canArchive,
  saveNetworkEnabled,
  archiveFile,
  studyFilters,
  studyPeriod,
  isDeleting,
  isArchiving,
}) => {
  const searchBy = ["studyName", "userDisplayName"];
  const { toolState, setToolState } = useContext(ToolContext);
  const { formState } = useContext(FormContext);
  const { StudyListViewLimitedEnabled, StudyNameSearchEnabled } = formState.clientSettings.features;

  const setToolStateProp = (prop, value) => {
    const _toolState = toolState;
    _toolState[prop] = {
      type: "warning",
      className: "warning",
      messages: value,
    };
    setToolState(_toolState);
  };

  const [match, update, term] = useSearch(loadedFiles, searchBy, StudyListViewLimitedEnabled);

  const handleChange = (e) => {
    const { value } = e.target;
    update(value);
  };

  const handleItemClick = (e) => {
    e.stopPropagation();
    const { studyId, studyName, action } = e.currentTarget.dataset;

    const showMessage = () =>
      setToolStateProp("alert", [
        {
          description: "Central load/save/delete disabled, please use import/export",
        },
      ]);

    switch (action) {
      case "load":
        return saveNetworkEnabled ? loadNetwork(studyId) : showMessage();
      case "archive":
        if (isArchiving === studyId) {
          return;
        }
        return saveNetworkEnabled ? archiveFile(e, studyName, studyId) : showMessage();
      case "delete":
        if (isDeleting === studyId) {
          return;
        }
        return saveNetworkEnabled ? deleteFile(e, studyName, studyId) : showMessage();
      default:
        return;
    }
  };

  const rowRenderer = ({
    index, // Index of row
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    key, // Unique key within array of rendered rows
    parent, // Reference to the parent List (instance)
    style, // Style object to be applied to row (to position it);
    // This must be passed through to the rendered row element.
  }) => {
    const { studyId, studyName, studyCreatedDate, userDisplayName, isOwner, areaId } = match[index];

    return (
      <div key={key} style={style}>
        <ListGroupItem
          key={studyId}
          tag="a"
          action
          data-study-id={studyId}
          data-action="load"
          onClick={handleItemClick}
          className="d-flex justify-content-between text-light"
        >
          <S.Row>
            <S.Grid noPadding gap={4}>
              <S.SpanEllipse data-testid={`study-name-${studyId}`} style={{ fontSize: ".875rem" }}>
                <EATooltip text={studyName} target={`studyName-${index}`} placement="top" />
                {searchBy.includes("studyName") ? (
                  getSpanList(studyName, term).map(({ h, s }, key) => (
                    <S.SmartSpan id={`studyName-${index}`} key={key} h={h}>
                      {s}
                    </S.SmartSpan>
                  ))
                ) : (
                  <S.SmartSpan id={`studyName-${index}`}>{studyName}</S.SmartSpan>
                )}
              </S.SpanEllipse>

              <S.SpanEllipse>
                <EATooltip
                  text={!StudyListViewLimitedEnabled && getAreaName(studyFilters, areaId)}
                  target={`area-${index}`}
                  placement="top"
                />
                <S.SmartSpan id={`area-${index}`}>
                  {!StudyListViewLimitedEnabled && getAreaName(studyFilters, areaId)}
                </S.SmartSpan>
              </S.SpanEllipse>

              <EATooltip
                text={formatDate(studyCreatedDate)}
                target={`date-${index}`}
                placement="top"
              />
              <S.SmartSpan id={`date-${index}`}>{formatDate(studyCreatedDate)}</S.SmartSpan>

              <S.SpanEllipse data-testid={`user-${studyId}`} style={{ fontSize: ".875rem" }}>
                {!StudyListViewLimitedEnabled && (
                  <EATooltip
                    text={userDisplayName}
                    target={`userdisplayname-${index}`}
                    placement="top"
                  />
                )}
                {!StudyListViewLimitedEnabled &&
                  (searchBy.includes("userDisplayName") ? (
                    getSpanList(userDisplayName, term).map(({ h, s }, key) => (
                      <S.SmartSpan id={`userdisplayname-${index}`} key={key} h={h}>
                        {s}
                      </S.SmartSpan>
                    ))
                  ) : (
                    <S.SmartSpan>{userDisplayName}</S.SmartSpan>
                  ))}
              </S.SpanEllipse>
            </S.Grid>

            {isOwner ? (
              <div className="d-flex align-self-center align-content-end">
                <button
                  className={`${
                    studyPeriod !== "archived" && canArchive ? "" : "ml-auto"
                  } align-items-center btn btn-danger btn-sm`}
                  data-study-id={studyId}
                  data-study-name={studyName}
                  data-action="delete"
                  onClick={handleItemClick}
                >
                  {isDeleting === studyId ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} />
                  )}
                </button>
                {canArchive && studyPeriod !== "archived" && (
                  <button
                    className="align-items-center btn btn-primary btn-sm ml-2"
                    onClick={handleItemClick}
                    data-study-name={studyName}
                    data-study-id={studyId}
                    data-action="archive"
                  >
                    {isArchiving === studyId ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      <FontAwesomeIcon icon={faArchive} />
                    )}
                  </button>
                )}
              </div>
            ) : null}
          </S.Row>
        </ListGroupItem>
      </div>
    );
  };

  return (
    <S.SearchFile>
      <S.Grid>
        {!!StudyNameSearchEnabled && (
          <>
            <S.Flex padding={[8, 14]} align="start">
              Search
            </S.Flex>

            <div>
              <InputGroup>
                <PromptInput
                  id="studySearchText"
                  type="text"
                  data-testid="file-search"
                  placeholder="Search by StudyName or User"
                  bsSize="sm"
                  value={term}
                  onChange={handleChange}
                />
              </InputGroup>

              <S.ToggleMessage show={true}>
                <S.SmartSpan>Files found: </S.SmartSpan>

                <S.SmartSpan>{match.length}</S.SmartSpan>
              </S.ToggleMessage>
            </div>
          </>
        )}
      </S.Grid>

      <S.ListVirtualised
        width={390}
        height={540}
        rowCount={match.length}
        rowHeight={52}
        rowRenderer={rowRenderer}
      />
    </S.SearchFile>
  );
};

export default SearchFile;
