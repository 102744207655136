import React from "react";
import { Button, InputGroup, InputGroupAddon } from "reactstrap";
import { PromptInput } from "./PromptInput";

const NetworkLoad = ({
  importFileName,
  label,
  fileInputId,
  textInputId,
  disabled = false,
  multiple = false,
  max = undefined,
  allowedFileTypes = undefined,
  placeholder = "",
}) => {
  return (
    <div className="m-3">
      <InputGroup className="mr-2">
        <InputGroupAddon>
          <label style={{ display: "inline" }}>
            <span
              className={`btn btn-primary btn-sm ${disabled ? "disabled" : ""}`}
              onChange={importFileName}
            >
              {label}
              <input
                type="file"
                id={fileInputId}
                style={{ display: "none" }}
                disabled={disabled}
                multiple={multiple}
                max={max}
                accept={allowedFileTypes}
              />
            </span>
          </label>
        </InputGroupAddon>
        <PromptInput
          type="text"
          id={textInputId}
          className="form-control form-control-sm"
          placeholder={placeholder}
          readOnly
        />
      </InputGroup>
    </div>
  );
};

export default NetworkLoad;
