import { Input, InputProps, Tooltip } from "reactstrap";
import { useContext, useState } from "react";
import { FormContext } from "../context/FormContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { InputPrompt } from "../model/reference/settingsTypes";
import "./PromptInput.css";

export const PromptInput = (props: InputProps) => {
  if (props.type && props.type !== "text") {
    throw new Error(`PromptInput does not currently support type ${props.type}`);
  }
  if (props.style?.width && !props.style.width.toString().match(/^\d+(px)?$/)) {
    throw new Error(`PromptInput does not currently support widths of type ${props.style.width}`);
  }

  const { formState } = useContext(FormContext);
  const inputPrompts: InputPrompt[] = formState?.clientSettings?.inputPrompts ?? [];
  const inputPrompt = inputPrompts.find((p) => p.targetControlId === props.id && p.displayText);
  const iconId = `${props.id}-icon`;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const extendedProps = {
    ...props,
    className: `${props.className ?? ""} prompt-input-textbox`.trimStart(),
  };

  const isFlex = !props.style?.width;

  return inputPrompt ? (
    <span className={`prompt-input-wrapper ${isFlex ? "d-flex flex-grow-1" : ""}`}>
      <FontAwesomeIcon icon={faQuestionCircle} className="prompt-input-icon" id={iconId} />
      <Input {...extendedProps}></Input>
      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        target={iconId}
        toggle={toggle}
        autohide={false}
      >
        {inputPrompt.displayText}
      </Tooltip>
    </span>
  ) : (
    <Input {...props}></Input>
  );
};

export default PromptInput;
